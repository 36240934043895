// Implements different search methods based on the object type (lookupTo)
const lookupSearchFilter = (lookupTo, records, searchTerm) => {
  const searchTerms = searchTerm.toLowerCase().split(' ');

  switch (lookupTo) {
    case 'users':
      return records.filter((record) => {
        return searchTerms.every((term) => {
          return (
            record?.firstName?.toLowerCase().includes(term) ||
            record?.lastName?.toLowerCase().includes(term)
          );
        });
      });
    case 'plans':
      return records.filter((record) => {
        return searchTerms.every((term) => {
          return (
            record?.name?.toLowerCase().includes(term)
          );
        });
      });
    default:
      // Default case if objectType is not recognized
      return records;
  }
};

// Sets the value that shows on the lookup field based on the object type (lookupTo)
const lookupFieldDisplayValue = (lookupTo, record) => {
  switch (lookupTo) {
    case 'users':
      return `${record?.firstName} ${record?.lastName}`
    case 'plans':
      return record?.name;
    default:
      // Default case if objectType is not recognized
      return 'lookupTo object unknown';
  }
};

export { lookupSearchFilter, lookupFieldDisplayValue };
