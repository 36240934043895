import api from './api';

const fetchData = async (url) => {
  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    throw error;
  }
};

/*
    Possible values for objectType: leads, tasks, users (All plural)

    example of filter:

    {
      filterLogic: '1 AND 2',
      filters: [
        {
          field: 'isActive',
          operator: 'notEqual',
          value: true
        },
        {
          field: 'code',
          operator: 'Equal',
          value: true
        },
      ]
    }

    where, operator: null (when only one filter exists), equals, notEqual, lessThan, greaterThan, lessOrEqual, greaterOrEqual, contains, notContain, startsWith, includes, excludes
    and, filterLogic: '1 AND 2', and or, '1 OR 2', etc
*/
const getRecords = async (objectType, filter) => {
  const url = `/api/records/${objectType}`;
  try {
    const response = await api.get(url,{
      params: { filter: filter }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    throw error;
  }
};

const getRecord = async (objectType, id) => {
  const url = `/api/record/${objectType}/${id}`;

  return await fetchData(url);
};

/*
 example of filter:

  {
    filterLogic: '1 AND 2',
    filters: [
      {
        field: 'isActive',
        operator: 'notEqual',
        value: true
      },
      {
        field: 'code',
        operator: 'equals',
        value: true
      },
    ]
  }

  where, operator: null (when only one filter exists), equals, notEqual, lessThan, greaterThan, lessOrEqual, greaterOrEqual, contains, notContain, startsWith, includes, excludes
  and, filterLogic: '1 AND 2', and or, '1 OR 2', etc
 */
const getRecordByFilter = async (objectType, filter) => {
  const url = `/api/record/${objectType}`;
  try {
    const response = await api.get(url,{
      params: { filter: filter }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    throw error;
  }
};

// Possible values for objectType: user, lead (Passed in as strings)
const deleteRecord = async (objectType, id) => {
  const url = `/api/record/${objectType}/${id}`;

  try {
    const response = await api.delete(url);
    return response.data;
  } catch (error) {
    console.error(`Error deleting ${objectType}`, error);
    throw error;
  }
}

// Possible values for objectType: leads, tasks, users (Passed in as strings)
const deleteRecords = async (objectType, ids) => {
  try {
    const url = `/api/records/${objectType}`;

    const response = await api.delete(url, {
      data: {
        ids: ids
      }
    });

    return response.data;
  } catch (error) {
    console.error(`Error deleting ${objectType}`, error);
    throw error;
  }
}

// Possible values for objectType: user, lead (Passed in as strings)
const updateRecord = async (objectType, id, data) => {
  const url = `/api/record/${objectType}/${id}`;
  try {
    const response = await api.put(url, data);
    return response.data;
  } catch (error) {
    console.error(`Error updating ${objectType}`, error);
    throw error;
  }
};

// Possible values for objectType: users, leads, tasks (Passed in as strings)
const updateRecords = async (objectType, ids, updates) => {
  const url = `/api/record/${objectType}`;
  try {
    if(objectType === 'users'){
      const response = await api.put(url, {
        userIds: ids,
        updates: updates
      });
      return response;
    }
  } catch (error) {
    console.error(`Error updating ${objectType}`, error);
    throw error;
  }
};

// Possible values for objectType: user, lead, task (Passed in as strings)
const createRecord = async (objectType, data) => {
  const url = `/api/record/${objectType}`;

  try {
    const response = await api.post(url, data);
    return response.data;
  } catch (error) {
      console.error(`Error creating ${objectType}`, error);
      throw error;
  }
};

// Possible values for objectType: users, leads, tasks (Passed in as strings)
const createRecords = async (objectTypePlural, records) => {
  try {
    const url = `/api/record/${objectTypePlural}`;
    const response = await api.post(url, records);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  getRecord,
  getRecords,
  getRecordByFilter,
  deleteRecord,
  deleteRecords,
  updateRecord,
  updateRecords,
  createRecord,
  createRecords
};