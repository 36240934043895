import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import RecordForm from '../../forms/RecordForm';

const CreateRecordModal = ({ record, open, onClose, heading, submitButtonLabel, objectName }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleCancel = () => {
        onClose(); // Close the modal
    };

    const onSave = () => {
        const form = document.getElementById(`new-${objectName}-form`);
        if (form) {
          form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
    };

    return (
        <Dialog fullWidth open={open}>
            <DialogTitle textAlign="center">{heading}</DialogTitle>
            {objectName &&
                <DialogContent>
                    <RecordForm
                        objectName={objectName}
                        isEditing={false}
                        record={record}
                        setIsSubmitting={setIsSubmitting}
                        onClose={handleCancel}
                    />
                </DialogContent>
            }
            <DialogActions sx={{ p: "1.25rem" }}>
                <Button size="small" onClick={handleCancel}>Cancel</Button>
                <Button disabled={isSubmitting} size="small" form="content-form" type="submit" variant="contained" onClick={onSave}>
                    {submitButtonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateRecordModal;