import React from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import getListViewColumnsByName from '../../../objectManager/getListViewColumnsByName';
import { getRecords } from '../../../api/crud';
import { formatDateTime } from '../../../utils/dateUtils';
import { Box, Grid, Paper, Typography } from '@mui/material';
import styles from './css/TechSupportDashboard.module.css';
import EventIcon from '@mui/icons-material/Event';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import PestControlOutlinedIcon from '@mui/icons-material/PestControlOutlined';

const TechSupportDashboard = () => {
  // Filter to only retrieve active users
  const staffFilter = {
    filterLogic: null,
    filters: [
      {
        field: 'isActive',
        operator: 'equals',
        value: true
      },
    ]
  };

  // Fetching staff data
  const { data: staff, isLoading } = useQuery({
    queryFn: () => getRecords('users', staffFilter),
    queryKey: ['staff'],
  });

  const { data: tasksData/*, isLoading: tasksLoading*/ } = useQuery({
    queryFn: () => getRecords('events', {}),
    queryKey: ['tasks-dashboard'],
  });
  const tasks = tasksData?.slice(0, 10) || []; // Get the first 10 tasks


  const { data: schoolsData/*, isLoading tasksLoading*/ } = useQuery({
    queryFn: () => getRecords('tenants', {}),
    queryKey: ['tenants-dashboard'],
  });
  const numberOfSchools = schoolsData?.length;


   // Filter to only get the incidents
   const taskFilter = {
    filterLogic: null,
    filters: [
      {
        field: 'type',
        operator: 'equals',
        value: 'Incident'
      },
    ]
  };

  const { data: incidents/*, isLoading: tasksLoading*/ } = useQuery({
    queryFn: () => getRecords('tasks', taskFilter),
    queryKey: ['cases-dashboard'],
  });
  const numberOfBugs = incidents?.length;

  // Memoize table columns
  const tableColumns = useMemo(() => getListViewColumnsByName('users'), []);

  // Material-React-Table configuration
  const table = useMaterialReactTable({
    columns: tableColumns,
    data: staff || [],
    enableRowActions: false,
    enableRowSelection: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    state: {
      isLoading,
    },
    initialState: {
      showGlobalFilter: false,
      density: 'compact',
    },
    muiTablePaperProps: {
      elevation: 0, // Remove MUI box shadow
      sx: {
        borderRadius: 'var(--border-radius)',
      },
    },
  });

  return (
    <div className={styles['dashboard-container']}>
      <Grid container spacing={3}>
        {/* Left section: Number of students, teachers, and Material React Table */}
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            {/* Box for number of students */}
            <Grid item xs={6}>
              <Paper elevation={3} className={styles['dashboard-box']}>
                <Typography variant="h6">Total Number of Schools</Typography>
                <Box display="flex" alignItems="center" justifyContent="center" gap="10px">
                  <Typography variant="h4" sx={{ mr: 1 }}>
                    {numberOfSchools}
                  </Typography>
                  <Avatar sx={{ bgcolor: 'var(--green-color)' }}>
                    <LocalLibraryOutlinedIcon />
                  </Avatar>
                </Box>
                {/* <Typography variant="subtitle2">
                  Last updated: 2024-07-06
                </Typography> */}
              </Paper>
            </Grid>
            {/* Box for number of teachers */}
            <Grid item xs={6}>
              <Paper elevation={3} className={styles['dashboard-box']}>
                <Typography variant="h6">Total Open Incidents</Typography>
                <Box display="flex" alignItems="center" justifyContent="center" gap="10px">
                  <Typography variant="h4">
                    {numberOfBugs}
                  </Typography>
                  <Avatar sx={{ bgcolor: 'var(--orange-color)' }}>
                    <PestControlOutlinedIcon />
                  </Avatar>
                </Box>
                {/* <Typography variant="subtitle2">
                  Last updated: 2024-07-06
                </Typography> */}
              </Paper>
            </Grid>
            {/* Material React Table */}
            <Grid item xs={12}>
              <Paper elevation={3} className={styles['material-react-table']}>
                <Typography sx={{marginBottom: '10px'}}>Active Users</Typography>
                <MaterialReactTable table={table} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {/* Right section: Today's Tasks */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} className={styles['upcoming-events']}>
            <Typography variant="h6" gutterBottom>
              Today's Tasks
            </Typography>
            {/* Render upcoming tasks */}
            {tasks?.length !== 0 ? (
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                }}
              >
                {tasks?.map((task) => (
                  <ListItemButton key={task?._id}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'var(--orange-color)' }}>
                        <EventIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={task?.title} secondary={formatDateTime(task?.dueDate)} />
                  </ListItemButton>
                ))}
              </List>
              ) : (
                <Typography variant="p" sx={{display: 'flex', justifyContent: 'center'}}>No Tasks To Display</Typography>
              )
            }
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default TechSupportDashboard;