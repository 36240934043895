import styles from '../css/AdmissionsPage.module.css';
import { useState, useEffect, useContext } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { AuthContext } from '../../../context/AuthContext';
import Field from '../../../components/field/Field';
import { Button, Container, Typography, Box, CircularProgress } from '@mui/material';
import { getRecordByFilter, updateRecord } from '../../../api/crud';
import validation from '../../../utils/validation';
import { toast } from 'react-toastify';

const initialSettings = {
  header: {
    title: '',
    subtitle: '',
  },
  process: {
    title: '',
    steps: '',
    applyButtonText: '',
  },
  importantDates: {
    title: '',
    dates: '',
  },
  contactInfo: {
    title: '',
    text: '',
    phone: '',
    email: '',
  }
};

const AdmissionsPage = () => {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialSettings);

  const { getTenantId } = useContext(AuthContext);
  const tenantId = getTenantId();
  
  const filter = {
    filterLogic: null,
    filters: [
      {
        field: 'tenantId',
        operator: 'equals',
        value: tenantId
      },
    ]
  };
  
  const { data: settings, isLoading } = useQuery({
    queryFn: () => getRecordByFilter('admissions-page', filter),
    queryKey: ['admissions-page'],
    enabled: !!tenantId,
    // onSuccess: (data) => {
    //   if (data) setFormData(data);
    // }
  });

  useEffect(() => {
    setFormData(settings);
  }, [settings]);

  const updatePage = useMutation({
    mutationFn: () => updateRecord('admissions-page', settings._id, formData),
    onSuccess: () => {
      queryClient.invalidateQueries(['admissions-page']);
      toast.success('Settings updated successfully!');
    },
    onError: (error) => {
      toast.error(`Error updating settings: ${error?.response?.data?.message}`);
    }
  });

  const handleInputChange = (section, name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [name]: value
      }
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Call the validation function to get errors
    const validationErrors = validation(formData, 'admissions-page');
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      updatePage.mutate();
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <Container className={styles.container}>
        <Typography variant="h4" component="h1" gutterBottom>
          Edit Admissions Page
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container className={styles.container}>
      <Typography variant="h4" component="h1" gutterBottom>
        Edit Admissions Page
      </Typography>
      <form onSubmit={handleSubmit}>
        <Field
          label="Header Title"
          name="title"
          type="text"
          value={formData?.header?.title}
          onChange={(e) => handleInputChange('header', 'title', e.target.value)}
          errorMessage={errors.title}
        />
        <Field
          label="Header Subtitle"
          name="subtitle"
          type="text"
          value={formData?.header?.subtitle}
          onChange={(e) => handleInputChange('header', 'subtitle', e.target.value)}
          errorMessage={errors.subtitle}
        />
        <Field
          label="Process Title"
          name="title"
          type="text"
          value={formData?.process?.title}
          onChange={(e) => handleInputChange('process', 'title', e.target.value)}
          errorMessage={errors.title}
        />
        <Field
          label="Steps"
          name="steps"
          type="richtext"
          value={formData?.process?.steps}
          onChange={(e) => handleInputChange('process', 'steps', e.target.value)}
          errorMessage={errors.steps}
        />
        <Field
          label="Apply Button Text"
          name="applyButtonText"
          type="text"
          value={formData?.process?.applyButtonText}
          onChange={(e) => handleInputChange('process', 'applyButtonText', e.target.value)}
        />
        <Field
          label="Important Dates Title"
          name="title"
          type="text"
          value={formData?.importantDates?.title}
          onChange={(e) => handleInputChange('importantDates', 'title', e.target.value)}
          errorMessage={errors.title}
        />
        <Field
          label="Dates"
          name="dates"
          type="richtext"
          value={formData?.importantDates?.dates}
          onChange={(e) => handleInputChange('importantDates', 'dates', e.target.value)}
          errorMessage={errors.dates}
        />
        <Field
          label="Contact Info Title"
          name="title"
          type="text"
          value={formData?.contactInfo?.title}
          onChange={(e) => handleInputChange('contactInfo', 'title', e.target.value)}
        />
        <Field
          label="Contact Info Text"
          name="text"
          type="text"
          value={formData?.contactInfo?.text}
          onChange={(e) => handleInputChange('contactInfo', 'text', e.target.value)}
          errorMessage={errors.title}
        />
        <Field
          label="Phone"
          name="phone"
          type="text"
          value={formData?.contactInfo?.phone}
          onChange={(e) => handleInputChange('contactInfo', 'phone', e.target.value)}
          errorMessage={errors.title}
        />
        <Field
          label="Email"
          name="email"
          type="email"
          value={formData?.contactInfo?.email}
          onChange={(e) => handleInputChange('contactInfo', 'email', e.target.value)}
          errorMessage={errors.email}
        />
        <Box className={styles.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default AdmissionsPage;