import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';

const schoolSidebarLinks = [
  {
    text: 'Dashboard',
    icon: <GridViewOutlinedIcon />,
    path: '/',
  },
  {
    text: 'Subjects',
    icon: <MenuBookOutlinedIcon />,
    path: '/admin/subjects',
  },
  {
    text: 'Events',
    icon: <CalendarTodayIcon />,
    path: '/admin/events',
  },
  {
    text: 'News',
    icon: <ArticleOutlinedIcon />,
    path: '/admin/news',
  },
  {
    text: 'Achievements',
    icon: <EmojiEventsOutlinedIcon />,
    path: '/admin/achievements',
  },
  {
    text: 'Testimonials',
    icon: <ThumbUpAltOutlinedIcon />,
    path: '/admin/testimonials',
  },
  {
    text: 'Gallery',
    icon: <CollectionsOutlinedIcon />,
    path: '/admin/gallery',
  },
  {
    text: 'Staff',
    icon: <PeopleAltOutlinedIcon />,
    path: '/admin/users',
  },
  {
    text: 'Pages',
    icon: <WebOutlinedIcon />,
    path: '/school-website-pages',
  },
  {
    text: 'Settings',
    icon: <SettingsOutlinedIcon />,
    path: '/school-settings',
  },
  {
    text: 'Log Out',
    icon: <LogoutOutlinedIcon />,
    listItemClass: 'log_out',
  },
];

const techSupportSidebarLinks = [
  {
    text: 'Dashboard',
    icon: <GridViewOutlinedIcon />,
    path: '/',
  },
  {
    text: 'Schools',
    icon: <LocalLibraryOutlinedIcon />,
    path: '/admin/tenants',
  },
  {
    text: 'Subscription Plans',
    icon: <LocalOfferOutlinedIcon />,
    path: '/admin/plans',
  },
  {
    text: 'Tasks',
    icon: <AssignmentOutlinedIcon />,
    path: '/admin/tasks',
  },
  {
    text: 'Users',
    icon: <PeopleAltOutlinedIcon />,
    path: '/admin/users',
  },
  {
    text: 'Settings',
    icon: <SettingsOutlinedIcon />,
    path: '/settings',
  },
  {
    text: 'Log Out',
    icon: <LogoutOutlinedIcon />,
    listItemClass: 'log_out',
  },
];

export { techSupportSidebarLinks, schoolSidebarLinks };
