const planColumns = () => [
    {
        accessorKey: 'name',
        header: 'Subscription Plan Name',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
    {
        accessorKey: 'description',
        header: 'Description',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
    {
        accessorKey: 'price',
        header: 'Price Per Month',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
];

export { planColumns };