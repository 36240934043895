import { formatDateTime } from '../../utils/dateUtils';

const taskColumns = () => [
  {
    accessorKey: 'title',
    header: 'Title',
    minSize: 100, //min size enforced during resizing
    maxSize: 400, //max size enforced during resizing
  },
  {
    accessorKey: 'status',
    header: 'Status',
    minSize: 100, //min size enforced during resizing
    maxSize: 400, //max size enforced during resizing
  },
  {
    accessorKey: 'type',
    header: 'Type',
    minSize: 100, //min size enforced during resizing
    maxSize: 400, //max size enforced during resizing
  },
  {
    accessorFn: (row) => row.dueDate ? formatDateTime(row.dueDate) : '', //accessorFn used to join multiple data into a single cell
    id: 'dueDate', //id is still required when using accessorFn instead of accessorKey
    header: 'Due Date',
    minSize: 100, //min size enforced during resizing
    maxSize: 400, //max size enforced during resizing
  },
];

export { taskColumns };
