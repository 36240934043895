import { userColumns } from './user/listView';
import { subjectColumns } from './subject/listView';
import { newsColumns } from './news/listView';
import { eventColumns } from './event/listView';
import { achievementColumns } from './achievement/listView';
import { testimonialColumns } from './testimonial/listView';
import { galleryColumns } from './gallery/listView';
import { schoolColumns } from './school/listView';
import { planColumns } from './tenantPlan/listView';
import { taskColumns } from './task/listView';

const columnsMap = {
    'users': userColumns(),
    'subjects': subjectColumns(),
    'news': newsColumns(),
    'events': eventColumns(),
    'tasks': taskColumns(),
    'achievements': achievementColumns(),
    'testimonials': testimonialColumns(),
    'gallery': galleryColumns(),
    'tenants': schoolColumns(),
    'plans': planColumns()
};

const getListViewColumnsByName = (objectType) => {
    // Return details if objectType exists in the map, otherwise return an empty array
    return columnsMap[objectType] || [];
};

export default getListViewColumnsByName;