import styles from './ContentInfoText.module.css';

function ContentInfoText({ p1, p2 }) {
  return (
    <div className={styles.info_text}>
      <p>{p1}</p>
      <p>{p2}</p>
    </div>
  );
}

export default ContentInfoText;