import { useState, useContext,useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { AuthContext } from '../../../context/AuthContext';
import Field from '../../../components/field/Field';
import { Button, Container, Typography, Box, CircularProgress } from '@mui/material';
import { getRecordByFilter, updateRecord } from '../../../api/crud';
import validation from '../../../utils/validation';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component

const initialSettings = {
  welcome: {
    welcomeMessage: '',
    introTextOne: '',
    introTextTwo: '',
    backgroundImage: '',
  },
  statistics: {
    title: '',
    subtitle: '',
    schoolOpenedYear: '',
    teachers: '',
    students: '',
    supportStaff: '',
  },
  vision: '',
  mission: '',
  newsAndEventsTitle: '',
  testimonialsTitle: ''
};

const HomePage = () => {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialSettings);

  const { getTenantId } = useContext(AuthContext);
  const tenantId = getTenantId();
  
  const filter = {
    filterLogic: null,
    filters: [
      {
        field: 'tenantId',
        operator: 'equals',
        value: tenantId
      },
    ]
  };
  
  const { data: settings, isLoading } = useQuery({
    queryFn: () => getRecordByFilter('home-page', filter),
    queryKey: ['home-page'],
    enabled: !!tenantId,
  });

  useEffect(() => {
    setFormData({
      welcome: {
        welcomeMessage: settings?.welcome?.welcomeMessage || '',
        introTextOne: settings?.welcome?.introTextOne || '',
        introTextTwo: settings?.welcome?.introTextTwo || '',
        backgroundImage: settings?.welcome?.backgroundImage || '',
      },
      statistics: {
        title: settings?.statistics?.title || '',
        subtitle: settings?.statistics?.subtitle || '',
        schoolOpenedYear: settings?.statistics?.schoolOpenedYear || '',
        teachers: settings?.statistics?.teachers || '',
        students: settings?.statistics?.students || '',
        supportStaff: settings?.statistics?.supportStaff || '',
      },
      vision: settings?.vision || '',
      mission: settings?.mission || '',
      newsAndEventsTitle: settings?.newsAndEventsTitle || '',
      testimonialsTitle: settings?.testimonialsTitle || ''
    });
  }, [settings]);

  const handleInputChange = (e, section) => {
    const { name, value } = e.target;

    const updatedData = section ? {
      ...formData,
      [section]: {
        ...formData[section],
        [name]: value
      }
    } : {
      ...formData,
      [name]: value
    }

    setFormData(updatedData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Call the validation function to get errors
    const validationErrors = validation(formData, 'home-page');
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true); // Disable the button when clicked
      try {
        const updatePromise = updateRecord('home-page', settings._id, formData);
        
        toast.promise(
          updatePromise,
          {
            pending: 'Updating settings...',
            success: 'Settings updated successfully! 👌',
            error: 'Error updating settings 🤯',
          }
        );

        await updatePromise;
        setIsSubmitting(false);

        queryClient.invalidateQueries(['home-page']);
      } catch (error) {
        setIsSubmitting(false);
        toast.error(`Error updating settings: ${error?.response?.data?.message}`);
        console.error('Error updating settings:', error?.response?.data?.message);
      }
    }
  };

  if (isLoading) {
    return (
      <Container>
        <Typography variant="h4" component="h1" gutterBottom>
          Edit Home Page
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Edit Home Page
      </Typography>
      <form onSubmit={handleSubmit}>
        <Field
          label="Welcome Message"
          name="welcomeMessage"
          type="text"
          value={formData.welcome.welcomeMessage}
          onChange={(e) => handleInputChange(e, 'welcome')}
          errorMessage={errors.welcome?.welcomeMessage}
        />
        <Field
          label="Intro Text One"
          name="introTextOne"
          type="text"
          value={formData.welcome.introTextOne}
          onChange={(e) => handleInputChange(e, 'welcome')}
          errorMessage={errors.welcome?.introTextOne}
        />
        <Field
          label="Intro Text Two"
          name="introTextTwo"
          type="text"
          value={formData.welcome.introTextTwo}
          onChange={(e) => handleInputChange(e, 'welcome')}
          errorMessage={errors.welcome?.introTextTwo}
        />
        <Field
          label="Background Image"
          name="backgroundImage"
          type="file"
          value={formData.welcome.backgroundImage}
          onChange={(e) => handleInputChange(e, 'welcome')}
        />
        <Field
          label="Title"
          name="title"
          type="text"
          value={formData.statistics.title}
          onChange={(e) => handleInputChange(e, 'statistics')}
          errorMessage={errors.statistics?.title}
        />
        <Field
          label="Subtitle"
          name="subtitle"
          type="text"
          value={formData.statistics.subtitle}
          onChange={(e) => handleInputChange(e, 'statistics')}
          errorMessage={errors.statistics?.subtitle}
        />
        <Field
          label="School Opened Year"
          name="schoolOpenedYear"
          type="date"
          value={formData.statistics.schoolOpenedYear ? formData.statistics.schoolOpenedYear.split('T')[0] : ''}
          onChange={(e) => handleInputChange(e, 'statistics')}
        />
        <Field
          label="Teachers"
          name="teachers"
          type="number"
          value={formData.statistics.teachers}
          onChange={(e) => handleInputChange(e, 'statistics')}
          errorMessage={errors.statistics?.teachers}
        />
        <Field
          label="Students"
          name="students"
          type="number"
          value={formData.statistics.students}
          onChange={(e) => handleInputChange(e, 'statistics')}
          errorMessage={errors.statistics?.students}
        />
        <Field
          label="Support Staff"
          name="supportStaff"
          type="number"
          value={formData.statistics.supportStaff}
          onChange={(e) => handleInputChange(e, 'statistics')}
          errorMessage={errors.statistics?.supportStaff}
        />
        <Field
          label="Vision"
          name="vision"
          type="textarea"
          value={formData.vision}
          onChange={(e) => handleInputChange(e, '')}
          errorMessage={errors.vision}
        />
        <Field
          label="Mission"
          name="mission"
          type="textarea"
          value={formData.mission}
          onChange={(e) => handleInputChange(e, '')}
          errorMessage={errors.mission}
        />
        <Field
          label="News and Events Title"
          name="newsAndEventsTitle"
          type="text"
          value={formData.newsAndEventsTitle}
          onChange={(e) => handleInputChange(e, '')}
          errorMessage={errors.newsAndEventsTitle}
        />
        <Field
          label="Testimonials Title"
          name="testimonialsTitle"
          type="text"
          value={formData.testimonialsTitle}
          onChange={(e) => handleInputChange(e, '')}
          errorMessage={errors.testimonialsTitle}
        />
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default HomePage;