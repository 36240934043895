import styles from './css/PasswordReset.module.css';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Copyright from '../../components/copyright/Copyright';
import SEO from '../../components/seo/SEO';
import { forgotPassword } from '../../api/emailSender';

function PasswordReset() {
  const [email, setEmail] = useState('');
  const [showError, setShowError] = useState(false);
  const [isResetRequested, setIsResetRequested] = useState(false);

  const customButtonStyle = {
    backgroundColor: 'var(--primary-color)',
    color: 'var(--secondary-text-color)',
    '&:hover': {
      backgroundColor: 'var(--primary-color-dark)',
    },
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleResetRequest = async () => {
    try {
      await forgotPassword('', email);
      setIsResetRequested(true);
    } catch (error) {
      setShowError(true);
      console.error();
    }
  };

  const handleTryAgainClick = () => {
    setShowError(false);
  };

  return (
    <div className={styles.content}>
      <SEO
        title="Forgot Your Password | Salesdam"
        description="Forgot Your Password"
      />
      <Typography variant="h4" gutterBottom>
        Password Reset
      </Typography>
      {showError ? (
        <Typography variant="body1">
          User with the email provided does not exist.{' '}
          <span
            onClick={handleTryAgainClick}
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              color: 'var(--primary-color)',
            }}
          >
            Try again!
          </span>
        </Typography>
      ) : (
        <>
          {isResetRequested ? (
            <div>
              <Typography variant="body1">
                Password reset instructions have been sent to your email.
              </Typography>
            </div>
          ) : (
            <div>
              <Typography variant="body1">
                Please enter your email to request a password reset.
              </Typography>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={handleEmailChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleResetRequest}
                sx={{ ...customButtonStyle, mt: 3, mb: 2 }}
                disabled={!email}
              >
                Reset Password
              </Button>
            </div>
          )}
        </>
      )}
      <Copyright />
    </div>
  );
}

export default PasswordReset;
