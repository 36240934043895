import userDetails from './user/details';
import subjectDetails from './subject/details';
import newsDetails from './news/details';
import eventDetails from './event/details';
import taskDetails from './task/details';
import achievementDetails from './achievement/details';
import testimonialDetails from './testimonial/details';
import galleryDetails from './gallery/details';
import schoolDetails from './school/details';
import planDetails from './tenantPlan/details';

const detailsMap = {
    'users': userDetails,
    'user': userDetails,
    'subjects': subjectDetails,
    'subject': subjectDetails,
    'news': newsDetails,
    'events': eventDetails,
    'event': eventDetails,
    'tasks': taskDetails,
    'task': taskDetails,
    'achievement': achievementDetails,
    'achievements': achievementDetails,
    'testimonial': testimonialDetails,
    'testimonials': testimonialDetails,
    'gallery': galleryDetails,
    'tenant': schoolDetails,
    'tenants': schoolDetails,
    'plan': planDetails,
    'plans': planDetails,
};

const getObjectDetailsByName = (objectType) => {
    // Return details if objectType exists in the map, otherwise return an empty array
    return detailsMap[objectType] || [];
};

export default getObjectDetailsByName;