const eventFields = [
  {
    label: 'Subject Name',
    name: 'name',
    placeholder: 'Enter Subject Name',
    id: 'name',
    type: 'text',
    default: '',
    maxLength: 70,
    required: true,
    fullSpan: true
  },
  {
    label: 'Subject Code',
    name: 'code',
    placeholder: 'Enter Subject Code',
    id: 'code',
    type: 'text',
    default: '',
    maxLength: 20,
    required: true,
    fullSpan: true
  },
  {
    label: 'Grade',
    name: 'grade',
    id: 'grade',
    type: 'picklist',
    options: [
      { label: 8, value: 8 },
      { label: 9, value: 9 },
      { label: 10, value: 10 },
      { label: 11, value: 11 },
      { label: 12, value: 12 }
    ],
    default: 8,
    required: true,
    fullSpan: true
  },
  {
    label: 'Description',
    name: 'description',
    placeholder: 'Enter Subject Description',
    id: 'description',
    type: 'text',
    default: '',
    maxLength: 250,
    required: true,
    fullSpan: true
  },
];

export default eventFields;
