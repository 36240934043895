const eventColumns = () => [
    {
        accessorKey: 'title',
        header: 'Title',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
    {
        accessorKey: 'body',
        header: 'Body',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
    {
        accessorKey: 'type',
        header: 'Type',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
    {
        accessorFn: (row) => row.createdBy ? `${row.createdBy.firstName} ${row.createdBy.lastName}` : '', //accessorFn used to join multiple data into a single cell
        id: 'author', //id is still required when using accessorFn instead of accessorKey
        header: 'Author',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    }
];

export { eventColumns };