import subjectFields from '../../objectManager/subject/fields';
import eventFields from '../../objectManager/event/fields';
import newsFields from '../../objectManager/news/fields';
import testimonialFields from '../../objectManager/testimonial/fields';
import achievementFields from '../../objectManager/achievement/fields';
import userFields from '../../objectManager/user/fields';
import galleryFields from '../../objectManager/gallery/fields';
import schoolFields from '../../objectManager/school/fields';
import planFields from '../../objectManager/tenantPlan/fields';
import taskFields from '../../objectManager/task/fields'

const getFieldsByObjectName = (objectType) => {
  switch (objectType) {
    case 'subject':
    case 'subjects':
      return subjectFields;
    case 'event':
    case 'events':
      return eventFields;
    case 'task':
    case 'tasks':
      return taskFields;
    case 'news':
      return newsFields;
    case 'testimonial':
    case 'testimonials':
      return testimonialFields;
    case 'achievement':
    case 'achievements':
      return achievementFields;
    case 'user':
    case 'users':
      return userFields;
    case 'gallery':
      return galleryFields;
    case 'tenant':
    case 'tenants':
      return schoolFields;
    case 'plan':
    case 'plans':
      return planFields;
    default:
      return [];
  }
};

export default getFieldsByObjectName;
