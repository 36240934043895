import SyncLoader from 'react-spinners/SyncLoader';

function CustomLoader({ loading }) {
  const color = 'var(--primary-color)';
  const override = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '100%',
    // height: '100vh'
  };

  return (
    <SyncLoader
      color={color}
      loading={loading}
      cssOverride={override}
      size={30}
      aria-label="Loading Spinner"
    />
  );
}

export default CustomLoader;