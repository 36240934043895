import styles from './css/DetailsPage.module.css';
import { Paper, Skeleton, Box } from '@mui/material';

const DetailsPageSkeletonLoading = () => {
    return (
      <div className={styles['details-page']}>
        <Paper elevation={1} style={{ padding: '20px' }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <Skeleton variant="text" width="60%" height={40} style={{ marginRight: '10px', borderRadius: 'var(--border-radius)' }} />
            <Box sx={{ display: 'flex', alignItems: 'center', width: '30%' }}>
              <Skeleton variant="rectangular" width={100} height={40} style={{ marginRight: '10px', borderRadius: 'var(--border-radius)' }} />
              <Skeleton variant="rectangular" width={100} height={40} style={{borderRadius: 'var(--border-radius)'}} />
            </Box>
          </Box>
          <Skeleton variant="text" width="100%" height={60} style={{ marginTop: '20px', borderRadius: 'var(--border-radius)' }} />
          <Skeleton variant="rectangular" width="100%" height={200} style={{ marginTop: '20px', borderRadius: 'var(--border-radius)' }} />
        </Paper>
      </div>
    );
};

export default DetailsPageSkeletonLoading;