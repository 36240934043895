import styles from './Layout.module.css';
import { useEffect, useState, useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from '../context/AuthContext';
import Sidebar from './sidebar/Sidebar';
import Toolbar from './toolbar/Toolbar';
import AutoLogout from '../components/autoLogout/AutoLogout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getRecordByFilter } from '../api/crud';
import Color from 'color';
import ClipLoader from 'react-spinners/ClipLoader';  // Import the ClipLoader from react-spinners

const Layout = ({ component }) => {
  const [hasMounted, setHasMounted] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const { getTenantId } = useContext(AuthContext);
  const tenantId = getTenantId();
  const TECH_SUPPORT_TENANT_ID = process.env.REACT_APP_TECH_SUPPORT_TENANT_ID;

  const filter = {
    filterLogic: null,
    filters: [
      {
        field: 'tenantId',
        operator: 'equals',
        value: tenantId
      },
    ]
  };

  const { data: settings, isLoading } = useQuery({
    queryFn: () => getRecordByFilter('settings', filter),
    queryKey: ['settings'],
    enabled: !!tenantId && tenantId !== TECH_SUPPORT_TENANT_ID,
  });

  const rootStyle = {
    '--primary-color': settings?.primaryColor,
    '--primary-color-light': settings?.primaryColor ? Color(settings?.primaryColor).lighten(0.5).hex() : ''
  };

  const toggleDrawer = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <>
      {hasMounted && (
        <AutoLogout>
          <div className={styles['layout']} style={rootStyle}>
            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              pauseOnHover
              theme="colored"
            />
            {isLoading ? (
              // Display the ClipLoader while settings are being fetched
              <div className={styles['spinner-container']}>
                <ClipLoader color="#16325B" size={50} />
              </div>
            ) : (
              <>
                <Sidebar isMenuOpen={isMenuOpen} />
                <section className={styles['content-body']}>
                  <Toolbar isMenuOpen={isMenuOpen} toggleDrawer={toggleDrawer} />
                  <main className={styles['main-content']}>
                    {component}
                  </main>
                </section>
              </>
            )}
          </div>
        </AutoLogout>
      )}
    </>
  );
};

export default Layout;