const taskFields = [
  {
    label: 'Title',
    name: 'title',
    id: 'taskTitle',
    type: 'text',
    placeholder: 'Enter Task Title',
    default: '',
    maxLength: 70,
    required: true,
    fullSpan: true
  },
  {
    label: 'Description',
    name: 'description',
    id: 'taskDescription',
    type: 'textarea',
    placeholder: 'Enter Task Description',
    default: '',
    maxLength: 250,
    required: true,
    fullSpan: true
  },
  {
    label: 'Status',
    name: 'status',
    id: 'status',
    type: 'picklist',
    options: [
      { label: 'In Progress', value: 'In Progress' },
      { label: 'Completed', value: 'Completed' },
    ],
    default: '',
    required: false,
    fullSpan: true
  },
  {
    label: 'type',
    name: 'type',
    id: 'taskType',
    type: 'picklist',
    options: [
      { label: 'Incident', value: 'Incident' },
      { label: 'General Task', value: 'General Task' },
    ],
    default: '',
    required: false,
    fullSpan: true
  },
  {
    label: 'Due Date',
    name: 'dueDate',
    id: 'dueDate',
    type: 'datetime',
    default: '',
    required: true,
    fullSpan: true
  },
  {
    label: 'Assigned To',
    name: 'assignedTo',
    id: 'assignedTo',
    type: 'lookup',
    lookupTo: 'users',
    required: true,
    fullSpan: true
  },
];

export default taskFields;
