import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

/**
 * Encrypts a given text using AES encryption.
 * @param {string} text - The text to encrypt.
 * @returns {string} The encrypted text.
 */
export const encryptText = (text) => {
  return CryptoJS.AES.encrypt(text, SECRET_KEY).toString();
};

/**
 * Decrypts a given encrypted text using AES decryption.
 * @param {string} encryptedText - The encrypted text to decrypt.
 * @returns {string} The decrypted text.
 */
export const decryptText = (encryptedText) => {
  const bytes = CryptoJS.AES.decrypt(encryptedText, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};