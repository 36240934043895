import styles from './css/NotFoundPage.module.css';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className={styles['not-found-container']}>
      <h1 className={styles['not-found-title']}>404</h1>
      <p className={styles['not-found-text']}>Oops! The page you are looking for does not exist.</p>
      <Link to="/" className={styles['home-link']}>Go Back Home</Link>
    </div>
  );
};

export default NotFoundPage;