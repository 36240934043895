import styles from './css/Dashboard.module.css';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import SEO from '../../components/seo/SEO';
import SchoolDashboard from './school/SchoolDashboard';
import TechSupportDashboard from './techSupport/TechSupportDashboard';

function Dashboard() {
  const { isTechSupportTenant } = useContext(AuthContext);
  const isTechSupport = isTechSupportTenant();

  return (
    <>
      <SEO title="Overview | EduTrack" />
      <div className={styles['home-content']}>
        {isTechSupport ? <TechSupportDashboard /> : <SchoolDashboard />}
      </div>
    </>
  );
}

export default Dashboard;
