import { useState, useEffect } from 'react';
import { createRecord, updateRecord } from '../../api/crud';
import validation from '../../utils/formValidation';
import Field from '../field/Field';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component
import getFieldsByObjectName from './getFieldsByObjectName';
import getObjectDetailsByName from '../../objectManager/getObjectDetailsByName';

const RecordForm = ({ objectName, isEditing, record, isSubmitting, setIsSubmitting, onClose }) => {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const fields = getFieldsByObjectName(objectName);
  const details = getObjectDetailsByName(objectName);

  const initialFormValues = fields.reduce((acc, field) => {
    acc[field?.name] = isEditing ? record?.[field?.name] : field?.default;
    return acc;
  }, {});

  useEffect(() => {
    setFormValues(initialFormValues);
  }, [record, isEditing, objectName]);

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;

    if (type === 'checkbox') {
      setFormValues({ ...formValues, [name]: event.target.checked });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Call the validation function to get errors
    const validationErrors = validation(formValues, details.apiName, fields);
    setErrors(validationErrors);

    // Create event and close the modal if there are no errors
    if (Object.keys(validationErrors).length === 0) {
      setFormValues({ ...formValues });
      if (!isEditing) {
        setIsSubmitting(true); // Disable the button when clicked

        // Logic for submitting event
        try {
          const createPromise = createRecord(details.apiName, formValues);

          toast.promise(createPromise, {
            pending: `Creating ${details.label}...`,
            success: `${details.label} created successfully! 👌`,
            error: `Error creating ${details.label} 🤯`,
          });

          const response = await createPromise;
          setIsSubmitting(false);
          if (response.message === 'Record created successfully') {
            setFormValues(initialFormValues);

            // Invalidate and refetch the query
            queryClient.invalidateQueries([details.pluralLabel]);

            onClose(); // Close the modal
          }
        } catch (error) {
          setIsSubmitting(false);
          toast.error(`Error creating record: ${error.response.data.message}`);
          console.error('Error creating record:', error.response.data.message);
        }
      } else {
        // Logic to update existing event
        setIsSubmitting(true); // Disable the button when clicked
        try {
          const updatePromise = updateRecord(details.apiName, record?._id, formValues);

          toast.promise(updatePromise, {
            pending: `Updating ${details.label}...`,
            success: `${details.label} updated successfully! 👌`,
            error: `Error updating ${details.label} 🤯`,
          });

          await updatePromise;
          setIsSubmitting(false);

          queryClient.invalidateQueries([details.pluralLabel]);
        } catch (error) {
          setIsSubmitting(false);
          toast.error(`Error updating record: ${error?.response?.data?.message}`);
          console.error('Error updating record:', error?.response?.data?.message);
        }
      }
    }
  };

  const formStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '16px',
    overflow: 'hidden',
  };

  return (
    <form style={formStyles} id={isEditing ? `edit-${details.apiName}-form` : `new-${details.apiName}-form`} onSubmit={handleSubmit}>
      {fields.map((field) => (
        <Field
          customStyles={{
            gridColumn: field.fullSpan ? 'span 2' : 'span 1',
          }}
          key={field.name}
          disabled={isSubmitting}
          id={field?.id}
          label={field?.label}
          required={field?.required}
          placeholder={field?.placeholder}
          type={field?.type}
          name={field?.name}
          options={field?.options}
          lookupTo={field?.lookupTo}
          maxLength={field?.maxLength}
          width={fields?.fileWidth}
          value={( field?.type === 'lookup' ? formValues?.[field?.name]?._id : formValues[field?.name] ) || field?.default}
          onChange={handleInputChange}
          errorMessage={errors[field?.name]}
        />
      ))}
    </form>
  );
};

export default RecordForm;
