import { createContext } from 'react';
import { useCookies } from 'react-cookie';
import { isLocalhost } from '../utils/isLocalhost';
import { encryptText } from '../utils/encryptionUtils';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'userId',
    'authKey',
    'tenantId',
    'authRefreshKey',
    'subdomain',
  ]);

  const cookieOptions = {
    path: '/',
    domain: isLocalhost() ? '.localhost.com' : `.${process.env.REACT_APP_DOMAIN}`,
  };

  const login = ({ user, accessToken, refreshToken, subDomain }) => {
    setCookie('userId', user?._id, cookieOptions);
    setCookie('authKey', encryptText(accessToken), cookieOptions);
    setCookie('authRefreshKey', encryptText(refreshToken), cookieOptions);
    setCookie('tenantId', user?.tenantId, cookieOptions);
    setCookie('subdomain', subDomain, cookieOptions);
  };

  const getUserId = () => {
    return cookies.userId;
  };

  const getTenantId = () => {
    return cookies.tenantId;
  };

  const isAuthenticated = () => {
    const userId = cookies.userId;
    const accessToken = cookies.authKey;
    const refreshToken = cookies.authRefreshKey;

    // Check if all required cookies exist
    return userId && accessToken && refreshToken;
  };

  const isTechSupportTenant = () => {
    return cookies.tenantId === process.env.REACT_APP_TECH_SUPPORT_TENANT_ID;
  }

  const logout = () => {
    removeCookie('authKey', cookieOptions);
    removeCookie('authRefreshKey', cookieOptions);
    removeCookie('userId', cookieOptions);
    removeCookie('tenantId', cookieOptions);
    removeCookie('subdomain', cookieOptions);
  };

  return (
    <AuthContext.Provider value={{ getUserId, getTenantId, isAuthenticated, isTechSupportTenant, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };