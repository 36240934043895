const newsFields = [
  {
    label: 'Title',
    name: 'title',
    id: 'title',
    type: 'text',
    placeholder: 'Enter News Title',
    default: '',
    maxLength: 70,
    required: true,
    fullSpan: true
  },
  {
    label: 'Published',
    name: 'published',
    id: 'published',
    type: 'checkbox',
    default: false,
    fullSpan: true
  },
  {
    label: 'Cover Photo',
    name: 'image',
    id: 'image',
    type: 'file',
    default: '',
    fileWidth: 800,
    required: false,
    fullSpan: true
  },
  {
    label: 'Body',
    name: 'body',
    id: 'eventBody',
    type: 'richtext',
    placeholder: 'Enter Event Body',
    default: '',
    maxLength: 6000,
    required: true,
    fullSpan: true
  },
];

export default newsFields;
