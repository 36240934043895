import styles from './RecordsListLiew.module.css';
import { useMemo, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getRecords } from '../../api/crud';
import SEO from '../../components/seo/SEO';
import ContentInfoText from '../../components/contentInfoText/ContentInfoText';
import getObjectDetailsByName from '../../objectManager/getObjectDetailsByName';
import getListViewColumnsByName from '../../objectManager/getListViewColumnsByName';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import RowActionMenuItems from './RowActionMenuItems';
import TopToolbar from './TopToolbar';
import NotFoundPage from '../notFoundPage/NotFoundPage';

const SCHOOLOBJECTS = ['subjects', 'events', 'news', 'achievements', 'testimonials', 'users', 'gallery'];
const TECHSUPPORTOBJECTS = ['tasks', 'users', 'tenants', 'plans'];

const RecordsListView = () => {
  const { isTechSupportTenant } = useContext(AuthContext);
  const supportedObjects = isTechSupportTenant() ? TECHSUPPORTOBJECTS : SCHOOLOBJECTS; // used to prevent making api calls for unsupported objects
  const { objectNamePlural } = useParams(); // Get the objectNamePlural param from the URL.
  const { data: records, isLoading } = useQuery({
    queryFn: () => getRecords(objectNamePlural, {}),
    queryKey: [objectNamePlural],
    enabled: supportedObjects.includes(objectNamePlural), // Only run the query if objectNamePlural is valid
  });

  const details = getObjectDetailsByName(objectNamePlural);
  const tableColumns = getListViewColumnsByName(objectNamePlural);

  const columns = useMemo(() => tableColumns, [tableColumns]);

  const table = useMaterialReactTable({
    columns,
    data: records || [],
    enableRowActions: true,
    enableRowSelection: true,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    state: {
      isLoading,
    },
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => <RowActionMenuItems closeMenu={closeMenu} row={row?.original} objectDetails={details} />,
    renderTopToolbarCustomActions: ({ table }) => <TopToolbar table={table} objectDetails={details} />
  });

  if(!supportedObjects.includes(objectNamePlural)){
    return <NotFoundPage />
  }

  return (
    <div className={styles['records-list-view']}>
      <SEO
        title={`${details?.pluralLabel} | EduTrack`}
        description={details?.description}
      />
      <ContentInfoText p1={details?.infoText} p2={details?.description} />
      <div className={styles['material-react-table']}>
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

export default RecordsListView;
