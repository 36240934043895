const schoolFields = [
  {
    label: 'School Name',
    name: 'name',
    id: 'tenantName',
    type: 'text',
    placeholder: 'Enter School Name',
    default: '',
    maxLength: 140,
    required: true,
    fullSpan: true
  },
  {
    label: 'Email',
    name: 'email',
    id: 'tenantEmail',
    type: 'email',
    default: '',
    maxLength: 320,
    required: true,
    fullSpan: true
  },
  {
    label: 'Phone',
    name: 'phone',
    id: 'phone',
    type: 'text',
    default: '',
    maxLength: 15,
    required: true,
    fullSpan: true
  },
  {
    label: 'type',
    name: 'type',
    id: 'tenantType',
    type: 'picklist',
    options: [
      { label: 'SCHOOL', value: 'SCHOOL' },
      { label: 'TECH SUPPORT', value: 'TECH SUPPORT' },
    ],
    default: 'SCHOOL',
    required: true,
    fullSpan: true
  },
  {
    label: 'School Phase',
    name: 'phase',
    id: 'phase',
    type: 'picklist',
    options: [
      { label: 'Primary School', value: 'Primary School' },
      { label: 'Secondary School', value: 'Secondary School' },
      { label: 'Combined School', value: 'Combined School' },
      { label: 'Other', value: 'Other' },
    ],
    default: 'Secondary School',
    required: true,
    fullSpan: true
  },
  {
    label: 'National EMIS NO',
    name: 'nationalEMISNumber',
    id: 'nationalEMISNumber',
    type: 'text',
    placeholder: `Enter School's National EMIS NO`,
    default: '',
    maxLength: 20,
    required: true,
    fullSpan: true
  },
  {
    label: 'Distrct Municipality',
    name: 'distrctMunicipality',
    id: 'distrctMunicipality',
    type: 'text',
    placeholder: `Enter Distrct Municipality`,
    default: '',
    maxLength: 140,
    required: true,
    fullSpan: true
  },
  {
    label: 'local Municipality',
    name: 'localMunicipality',
    id: 'localMunicipality',
    type: 'text',
    placeholder: `Enter local Municipality`,
    default: '',
    maxLength: 140,
    required: true,
    fullSpan: true
  },
  {
    label: 'Tenant Plan',
    name: 'plan',
    id: 'plan',
    type: 'lookup',
    lookupTo: 'plans',
    default: null,
    required: true,
    fullSpan: true
  },
  // {
  //   label: 'Domain Names',
  //   name: 'domainNames',
  //   id: 'domainNames',
  //   type: 'list',
  //   default: '',
  //   maxLength: 320,
  //   required: true,
  //   fullSpan: true
  // },
];

export default schoolFields;
