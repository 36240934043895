const sortAsc = (records, sortByField) => {
    const sortedRecords = records?.sort(
        (a, b) => new Date(b[sortByField]) - new Date(a[sortByField])
    );

    return sortedRecords;
};

const sortDesc = (records, sortByField) => {
    const sortedRecords = records?.sort(
        (a, b) => new Date(a[sortByField]) - new Date(b[sortByField])
    );

    return sortedRecords;
};

export { sortAsc, sortDesc };