import styles from './css/DetailsPage.module.css';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useQuery } from 'react-query';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Paper, Typography, Box, Button, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ActionButton from '../../components/actionButton/ActionButton';
import RecordForm from '../../components/forms/RecordForm';
import getObjectDetailsByName from '../../objectManager/getObjectDetailsByName';
import { getRecord, deleteRecord } from '../../api/crud';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component
import DetailsPageSkeletonLoading from './DetailsPageSkeletonLoading';
import NotFoundPage from '../notFoundPage/NotFoundPage';
import SEO from '../../components/seo/SEO';

const SCHOOLOBJECTS = ['subject', 'event', 'news', 'achievement', 'testimonial', 'user', 'gallery'];
const TECHSUPPORTOBJECTS = ['task', 'user', 'tenant', 'plan'];

const DetailsPage = () => {
  const { isTechSupportTenant } = useContext(AuthContext);
  const supportedObjects = isTechSupportTenant() ? TECHSUPPORTOBJECTS : SCHOOLOBJECTS; // used to prevent making api calls for unsupported objects
  const navigate = useNavigate();
  const { objectName, recordId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false); // New state to track deletion

  const details = getObjectDetailsByName(objectName);

  const { data: record, isLoading, error } = useQuery({
    queryFn: () => getRecord(objectName, recordId),
    queryKey: [objectName, recordId],
    enabled: !!objectName && !!recordId && !isDeleted && supportedObjects.includes(objectName)
  });

  useEffect(() => {
    if (isDeleted && !error) {
      navigate(`/admin/${details?.apiNamePlural}`);
    }
  }, [isDeleted, navigate, details, error]);

  const onSave = () => {
    const form = document.getElementById(`edit-${objectName}-form`);
    if (form) {
      form.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
    }
  };

  const onCancel = () => {
    navigate(`/admin/${details?.apiNamePlural}`);
  };

  const onDelete = async () => {
    handleClose(); // Close the menu
    if (window.confirm(`Are you sure you want to delete this ${objectName}?`)) {
      try {
        setIsSubmitting(true);
        setIsDeleted(true); // Set the deletion state
        await deleteRecord(objectName, recordId);
        toast.success(`Record deleted successfully! 👌`);
      } catch (error) {
        toast.error(`Error deleting record 🤯`);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleMoreOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isLoading) {
    return <DetailsPageSkeletonLoading />
  }

  const outlinedStlyes = {
    color: 'var(--primary-color)',
    border: '1px solid var(--primary-color)'
  };

  if(!supportedObjects.includes(objectName)){
    return <NotFoundPage />
  }

  return (
    <div className={styles['details-page']}>
      <SEO
        title={`${objectName[0].toUpperCase() + objectName.substring(1)} | EduTrack`}
        description={objectName}
      />
      <Paper elevation={1} style={{ padding: '20px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography gutterBottom>
            <Link
              to={`/admin/${details?.apiNamePlural}`}
              className={styles['list-view-link']}
            >
              list view &gt; {details?.pluralLabel}
            </Link>
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <div className={styles['button-group']}>
              <ActionButton
                actionType="save"
                onClick={onSave}
                isSubmitting={isSubmitting}
                style={{ marginRight: '10px' }}
              />
              <ActionButton
                actionType="cancel"
                onClick={onCancel}
                isSubmitting={isSubmitting}
                style={{ marginRight: '10px' }}
              />
            </div>
            <Button
              onClick={handleMoreOptionsClick}
              endIcon={<ArrowDropDownIcon />}
              variant='outlined'
              size='small'
              sx={outlinedStlyes}
            >
              More
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={onDelete}>Delete</MenuItem>
            </Menu>
          </Box>
        </Box>
        <Typography variant="h4" gutterBottom>
          Details Page
        </Typography>
        <RecordForm
          objectName={objectName}
          isEditing={true}
          record={record}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
        />
      </Paper>
    </div>
  );
};

export default DetailsPage;