const galleryFields = [
  {
    label: 'Published',
    name: 'published',
    id: 'published',
    type: 'checkbox',
    default: true,
    fullSpan: true
  },
  {
    label: 'Title',
    name: 'title',
    placeholder: 'Enter Picture Title',
    id: 'tile',
    type: 'text',
    default: '',
    maxLength: 70,
    required: true,
    fullSpan: true
  },
  {
    label: 'Description',
    name: 'description',
    placeholder: 'Enter Picture Description',
    id: 'description',
    type: 'text',
    default: '',
    maxLength: 150,
    required: true,
    fullSpan: true
  },
  {
    label: 'Picture',
    name: 'picture',
    id: 'picture',
    type: 'file',
    default: '',
    required: true,
    fullSpan: true
  },
];

export default galleryFields;
