// Used in the files: UserModal.js,..

function validation(formValues, formType, fields){
    const errors = {};

    // validate required fields
    fields?.forEach((field) => {
        const value = formValues[field.name];
        if (field.required && (value === '' || value === undefined)) {
            errors[field.name] = `${field.label} is required`;
        }
    });

    // Form-specific validation logic...
    if (formType === 'user') {
        // User-specific validation logic...

        if (formValues.title === '') {
            errors.title = 'Title is Required';
        }

        if (formValues.firstName === '') {
            errors.firstName = 'First Name is Required';
        }

        if (formValues.lastName === '') {
            errors.lastName = 'Last Name is Required';
        }

        if (formValues.email === '') {
            errors.email = 'Email is Required';
        }

        if (formValues.status === '') {
            errors.status = 'Status is Required';
        }

        if (formValues.role === '') {
            errors.role = 'Role is Required';
        }

        if (!formValues.joined) {
            errors.joined = 'Date is Required';
        }

        if (!/^0\d{9}$/.test(formValues.phone) && formValues.phone !== '' ) {
            errors.phone = 'Phone must follow South Africa\'s standard format. e.g 0723456789';
        }

      }  else if (formType === 'settings') {

        if (formValues?.schoolName === '') {
            errors.schoolName = 'School Name is Required';
        }

        if (formValues.mission === '') {
            errors.mission = `School's mission is Required`;
        }

        if (formValues.phase === '') {
            errors.phase = 'Phase is Required';
        }

        if (formValues.coverPhoto === '') {
            errors.coverPhoto = 'Cover Photo is Required';
        }

        if (formValues.logo === '') {
            errors.logo = 'Logo is Required';
        }

      } else if (formType === 'subject') {

        if (formValues.name === '') {
            errors.name = 'Subject Name is Required';
        }

        if (formValues.code === '') {
            errors.code = 'Code is Required';
        }

        if (formValues.grade === '') {
            errors.grade = 'Grade is Required';
        }

      } else if (formType === 'event'){

        if (formValues.title === '') {
            errors.title = 'Title is Required';
        }

        if (formValues.eventDate === '') {
            errors.eventDate = 'Event Date is Required';
        }

        if (formValues.body === '') {
            errors.body = 'Body is Required';
        }

        // if (formValues.body.length >= 100) {
        //     errors.body = 'Body must be less than 100 characters';
        // }

      } else if (formType === 'task'){

        if (formValues.title === '') {
            errors.title = 'Title is Required';
        }

        if (formValues.description === '') {
            errors.description = 'Description is Required';
        }

        if (formValues.status === '') {
            errors.status = 'Status is Required';
        }

        if (formValues.dueDate === '') {
            errors.eventDate = 'Due Date is Required';
        }

      } else if (formType === 'plan'){

        if (formValues.name === '') {
            errors.name = 'School Plan Name is Required';
        }

        if (formValues.maxEvents <= 0) {
            errors.maxEvents = 'Max Event is Invalid';
        }

        if (formValues.maxNews <= 0) {
            errors.maxNews = 'Max News is Invalid';
        }

        if (formValues.maxStorage <= 0) {
            errors.maxStorage = 'Max Storage is Invalid';
        }

      } else if (formType === 'register'){

        if(formValues.password !== formValues.confirmPassword ){
            errors.confirmPassword = 'Passwords do not match';
        }

        if(formValues.firstName === '' ){
            errors.firstName = 'First Name is Required';
        }

        if(formValues.lastName === '' ){
            errors.lastName = 'Last Name is Required';
        }

        if(formValues.email === '' ){
            errors.email = 'Email is Required';
        }

        if(formValues.password === '' ){
            errors.password = 'password is Required';
        }

        // if(formValues.confirmPassword === '' ){
        //     errors.confirmPassword = 'password is Required';
        // }

      } else if (formType === 'news'){

        if (formValues.title === '') {
            errors.title = 'Title is Required';
        }

        if (formValues.body === '') {
            errors.body = 'Body is Required';
        }

      } else if (formType === 'school'){

        if (formValues.name === '') {
            errors.name = 'Name is Required';
        }

        if (formValues.email === '') {
            errors.email = 'Email is Required';
        }

        if (formValues.phone === '') {
            errors.phone = 'Phone is Required';
        }

      } else if (formType === 'school'){

        if (formValues.schoolName === '') {
            errors.schoolName = 'School Name is Required';
        }

        if (formValues.email === '') {
            errors.email = 'Email is Required';
        }

        if (formValues.telephone === '') {
            errors.telephone = 'Phone is Required';
        }

        if (formValues.phase === '') {
            errors.phase = 'Phase is Required';
        }

        if (formValues.primaryColor === '') {
            errors.primaryColor = 'Primary Color is Required';
        }

        if (formValues.primaryColorHover === '') {
            errors.primaryColorHover = 'Primary Color Hover is Required';
        }

        if (formValues.tenantId === '') {
            errors.tenantId = 'Tenant ID is Required';
        }

      } else if (formType === 'login'){

        if (formValues.email === '') {
            errors.email = 'Email is Required';
        }

        if (formValues.password === '') {
            errors.password = 'Password is Required';
        }
      } else if (formType === 'register') {
        if (formValues.firstName === '') {
            errors.firstName = 'First Name is Required';
        }

        if (formValues.lastName === '') {
            errors.lastName = 'Last Name is Required';
        }

        if (formValues.email === '') {
            errors.email = 'Email is Required';
        }

        if (formValues.password === '') {
            errors.password = 'Password is Required';
        }
    }

    return errors;
};

export default validation;