import styles from './css/Settings.module.css';
import { useState, useEffect, useContext } from 'react';
import { useQuery/*, useQueryClient*/ } from 'react-query';
import { AuthContext } from '../../../context/AuthContext';
import { Button } from '@mui/material';
import CustomLoader from '../../../components/customLoader/CustomLoader';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getRecordByFilter, updateRecord } from '../../../api/crud';
import validation from '../../../utils/validation';
import Field from '../../../components/field/Field';
import SEO from '../../../components/seo/SEO';
import { toast } from 'react-toastify';

const initialSettings = {
  schoolName: '',
  telephone: '',
  showAchievements: false,
  showTestimonials: false,
  showApplicationForm: false,
  email: '',
  phase: '',
  logo: '',
  address: {
    addressLine1: '',
    addressLine2: '',
    street: '',
    city: '',
    province: '',
    zipCode: '',
    googleMapsAddressLink: '',
  },
  primaryColor: '',
};

function Settings() {
  // const queryClient = useQueryClient();
  const { getTenantId } = useContext(AuthContext);
  const tenantId = getTenantId();
  
  const filter = {
    filterLogic: null,
    filters: [
      {
        field: 'tenantId',
        operator: 'equals',
        value: tenantId
      },
    ]
  };
  
  const { data: settings, isLoading } = useQuery({
    queryFn: () => getRecordByFilter('settings', filter),
    queryKey: ['settings'],
    enabled: !!tenantId,
  });

  const [formData, setFormData] = useState(initialSettings);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  // const [isCanceling, setIsCanceling] = useState(false);

  useEffect(() => {
    setFormData({
      schoolName: settings?.schoolName || initialSettings.schoolName,
      telephone: settings?.telephone || initialSettings.telephone,
      showAchievements: settings?.showAchievements || initialSettings.showAchievements,
      showTestimonials: settings?.showTestimonials || initialSettings.showTestimonials,
      showApplicationForm: settings?.showApplicationForm || initialSettings.showApplicationForm,
      email: settings?.email || initialSettings.email,
      phase: settings?.phase || initialSettings.phase,
      logo: settings?.logo || initialSettings.logo,
      address: {
        addressLine1: settings?.address?.addressLine1 || initialSettings.address.addressLine1,
        addressLine2: settings?.address?.addressLine2 || initialSettings.address.addressLine2,
        street: settings?.address?.street || initialSettings.address.street,
        city: settings?.address?.city || initialSettings.address.city,
        province: settings?.address?.province || initialSettings.address.province,
        zipCode: settings?.address?.zipCode || initialSettings.address.zipCode,
        googleMapsAddressLink: settings?.address?.googleMapsAddressLink || initialSettings.address.googleMapsAddressLink,
      },
      primaryColor: settings?.primaryColor || initialSettings.primaryColor
    });
  }, [settings]);  

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;

    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: event.target.checked });
    } else if (name.includes('address')) {
      const [outerName, innerName] = name.split('.'); // Split the nested field name

      setFormData((prevSettings) => ({
        ...prevSettings,
        [outerName]: {
          ...prevSettings[outerName],
          [innerName]: value,
        },
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Call the validation function to get errors
    const validationErrors = validation(formData, 'settings');
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true); // Disable the button when clicked

      toast.promise(
        updateRecord('settings', settings?._id, formData),
        {
          pending: 'Updating settings...',
          success: 'Settings Updated Successfully 👌',
          error: 'Error updating settings 🤯'
        }
      ).then(() => {
        setIsSubmitting(false);
      }).catch(error => {
        setIsSubmitting(false);
        console.error('Error modifying settings:', error);
      });
    }
  };

  // const handleReset = async () => {
  //   setIsCanceling(true);
  //   queryClient.invalidateQueries(['settings']);
  // };

  const phaseOptions = [
    { label: 'Primary School', value: 'Primary School' },
    { label: 'Secondary School', value: 'Secondary School' }
  ];

  return (
    <>
      <SEO title="Settings | EduTrack" />
      {isLoading ? (
        <CustomLoader isLoading={isLoading} />
      ) : (
        <div className={styles['settings']}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>General Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form id="general-settings-form" onSubmit={handleSubmit}>
                <Field
                  id="schoolName"
                  label="School Name"
                  placeholder="Enter School Name"
                  type="text"
                  name="schoolName"
                  value={formData.schoolName}
                  onChange={handleInputChange}
                  errorMessage={errors.schoolName}
                />
                <Field
                  id="telephone"
                  label="Telephone"
                  placeholder="Enter School's Telephone"
                  type="text"
                  name="telephone"
                  value={formData.telephone}
                  onChange={handleInputChange}
                  errorMessage={errors.telephone}
                />
                <Field
                  id="email"
                  label="Email"
                  placeholder="Enter School's Email"
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  errorMessage={errors.email}
                />
                <Field
                  id="phase"
                  label="Phase"
                  placeholder="---Select School Phase---"
                  options={phaseOptions}
                  type="picklist"
                  name="phase"
                  value={formData.phase}
                  onChange={handleInputChange}
                  errorMessage={errors.phase}
                />
                <Field
                  id="showAchievements"
                  label="Show Achievements"
                  type="checkbox"
                  name="showAchievements"
                  value={formData?.showAchievements}
                  onChange={handleInputChange}
                  errorMessage={errors.showAchievements}
                />
                <Field
                  id="showTestimonials"
                  label="Show Testimonials"
                  type="checkbox"
                  name="showTestimonials"
                  value={formData?.showTestimonials}
                  onChange={handleInputChange}
                  errorMessage={errors.showTestimonials}
                />
                <Field
                  id="showApplicationForm"
                  label="Show Application Form"
                  type="checkbox"
                  name="showApplicationForm"
                  value={formData?.showApplicationForm}
                  onChange={handleInputChange}
                  errorMessage={errors.showApplicationForm}
                />
                <div className={styles.buttons}>
                  {/* <Button
                    onClick={handleReset}
                    disabled={isCanceling}
                    size="small"
                  >
                    {isCanceling ? 'Reverting..' : 'Revert'}
                  </Button> */}
                  <Button
                    form="general-settings-form"
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    size="small"
                  >
                    {isSubmitting ? 'Saving Form Data..' : 'Save'}
                  </Button>
                </div>
              </form>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Address & Contact Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form id="address-details-form" onSubmit={handleSubmit}>
                <Field
                  id="addressLine1"
                  label="Address Line 1"
                  placeholder="Enter Address Line 1"
                  type="text"
                  name="address.addressLine1"
                  value={formData.address.addressLine1}
                  onChange={handleInputChange}
                  errorMessage={errors['address.addressLine1']}
                />
                <Field
                  id="addressLine2"
                  label="Address Line 2"
                  placeholder="Enter Address Line 2"
                  type="text"
                  name="address.addressLine2"
                  value={formData.address.addressLine2}
                  onChange={handleInputChange}
                  errorMessage={errors['address.addressLine2']}
                />
                <Field
                  id="street"
                  label="Street"
                  placeholder="Enter Street"
                  type="text"
                  name="address.street"
                  value={formData.address.street}
                  onChange={handleInputChange}
                  errorMessage={errors['address.street']}
                />
                <Field
                  id="city"
                  label="City"
                  placeholder="Enter City"
                  type="text"
                  name="address.city"
                  value={formData.address.city}
                  onChange={handleInputChange}
                  errorMessage={errors['address.city']}
                />
                <Field
                  id="province"
                  label="Province"
                  placeholder="Enter Province"
                  type="text"
                  name="address.province"
                  value={formData.address.province}
                  onChange={handleInputChange}
                  errorMessage={errors['address.province']}
                />
                <Field
                  id="zipCode"
                  label="Zip Code"
                  placeholder="Enter Zip Code"
                  type="text"
                  name="address.zipCode"
                  value={formData.address.zipCode}
                  onChange={handleInputChange}
                  errorMessage={errors['address.zipCode']}
                />
                <Field
                  id="googleMapsAddressLink"
                  label="Google Maps Address Link"
                  placeholder="Enter Google Maps Address Link"
                  type="text"
                  name="address.googleMapsAddressLink"
                  value={formData.address.googleMapsAddressLink}
                  onChange={handleInputChange}
                  errorMessage={errors['address.googleMapsAddressLink']}
                />
                <div className={styles.buttons}>
                  {/* <Button
                    onClick={handleReset}
                    disabled={isCanceling}
                    size="small"
                  >
                    {isCanceling ? 'Reverting..' : 'Revert'}
                  </Button> */}
                  <Button
                    form="address-details-form"
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    size="small"
                  >
                    {isSubmitting ? 'Saving Form Data..' : 'Save'}
                  </Button>
                </div>
              </form>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>Branding</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form id="branding-form" onSubmit={handleSubmit}>
                <Field
                  id="primaryColor"
                  label="Primary Color"
                  placeholder="Enter Primary Color"
                  type="text"
                  name="primaryColor"
                  value={formData.primaryColor}
                  onChange={handleInputChange}
                  errorMessage={errors.primaryColor}
                />
                <Field
                  id="logo"
                  label="School Emblem"
                  type="file"
                  name="logo"
                  value={formData.logo}
                  onChange={handleInputChange}
                  errorMessage={errors.logo}
                />
                <div className={styles.buttons}>
                  {/* <Button
                    onClick={handleReset}
                    disabled={isCanceling}
                    size="small"
                  >
                    {isCanceling ? 'Reverting..' : 'Revert'}
                  </Button> */}
                  <Button
                    form="branding-form"
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    size="small"
                  >
                    {isSubmitting ? 'Saving Form Data..' : 'Save'}
                  </Button>
                </div>
              </form>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </>
  );
}

export default Settings;