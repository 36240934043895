import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Button } from '@mui/material';
import CreateRecordModal from '../../components/modals/createRecord/CreateRecordModal';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component
import { deleteRecords } from '../../api/crud';

const TopToolbar = ({ table, objectDetails }) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);

  const handleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete the selected ${objectDetails.pluralLabel}?`)) {
      const selectedRows = table.getSelectedRowModel().rows; // Get selected rows
      const recordIds = selectedRows.map(row => row.original._id); // Get the record ids from the selected rows

      const deletePromise = deleteRecords(objectDetails.apiNamePlural, recordIds);

      toast.promise(
        deletePromise,
        {
          pending: 'Deleting records...',
          success: 'Records deleted successfully!',
          error: {
            render({ data }) {
              return `Error deleting records: ${data?.response?.data?.message || data.message}`;
            }
          }
        }
      );

      try {
        await deletePromise;
        table.resetRowSelection(); // Unselect the rows
        queryClient.invalidateQueries([objectDetails.apiNamePlural]); // Refresh list view query (records)
      } catch (error) {
        console.error('Error deleting tasks:', error);
      }
    }
  };

  const handleAddRecord = () => {
    setOpen(true)
  };

  // const handleImportk = () => {
  //   alert('Clicked!');
  // };

  return (
    <>
      {open &&
        <CreateRecordModal
          open={open}
          record={null}
          onClose={() => setOpen(false)}
          heading={`Add ${objectDetails.label}`}
          submitButtonLabel='Save'
          objectName={objectDetails.apiName}
        />
      }
      <div style={{ display: 'flex', gap: '0.5rem' }}>
        <Button
          color="info"
          onClick={handleAddRecord}
          variant="contained"
          disabled={table.getSelectedRowModel()?.rows?.length !== 0} // Disable the button when rows are selected
          size="small"
        >
          New
        </Button>
        {/* <Button
          color="info"
          onClick={handleImportk}
          variant="contained"
          disabled={table.getSelectedRowModel()?.rows?.length !== 0} // Disable the button when rows are selected
          size="small"
        >
          IMPORT
        </Button> */}
        <Button
          color="error"
          onClick={handleDelete}
          variant="contained"
          disabled={table.getSelectedRowModel()?.rows?.length === 0} // Enable the button when rows are selected
          size="small"
        >
          Delete
        </Button>
      </div>
    </>
  );
};

export default TopToolbar;
