import styles from './Sidebar.module.css';
import SidebarItems from './SidebarItems';

const Sidebar = ({ isMenuOpen }) => {

  // Used for toggling sidebar
  const activeSidebarStyles = {
    width: '60px',
  };

  return (
    <div className={styles['sidebar']} style={isMenuOpen ? {} : activeSidebarStyles}>
      <div
        className={styles['logo-details']}
        style={isMenuOpen ? {} : { visibility: 'hidden' }}
      >
        <span className={styles['logo_name']}>EduTrack</span>
      </div>
      <SidebarItems isMenuOpen={isMenuOpen} />
    </div>
  );
};

export default Sidebar;
