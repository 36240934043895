const userFields = [
  {
    label: 'Picture',
    name: 'picture',
    id: 'picture',
    type: 'file',
    default: '',
    fullSpan: true
  },
  {
    label: 'Bio',
    name: 'bio',
    id: 'bio',
    type: 'textarea',
    maxLength: 150,
    default: '',
    fullSpan: true
  },
  {
    label: 'First Name',
    name: 'firstName',
    id: 'firstName',
    type: 'text',
    default: '',
    maxLength: 60,
    required: true,
    fullSpan: false
  },
  {
    label: 'Last Name',
    name: 'lastName',
    id: 'lastName',
    type: 'text',
    default: '',
    maxLength: 60,
    required: true,
    fullSpan: false
  },
  {
    label: 'Title',
    name: 'title',
    placeholder: 'Select Title',
    id: 'title',
    type: 'picklist',
    options: [
      { label: 'Mr', value: 'Mr' },
      { label: 'Mrs', value: 'Mrs' },
      { label: 'Ms', value: 'Ms' },
      { label: 'Miss', value: 'Miss' }
    ],
    default: 'Mr',
    required: true,
    fullSpan: false
  },
  {
    label: 'Email',
    name: 'email',
    id: 'email',
    type: 'email',
    default: '',
    maxLength: 320,
    required: true,
    fullSpan: false
  },
  {
    label: 'Date of Birth',
    name: 'dateOfBirth',
    id: 'dateOfBirth',
    type: 'date',
    default: '',
    required: false,
    fullSpan: false
  },
  {
    label: 'Date Joined',
    name: 'joined',
    id: 'dateOfBirth',
    type: 'date',
    default: '',
    required: false,
    fullSpan: false
  },
  {
    label: 'Phone',
    name: 'phone',
    id: 'phone',
    type: 'text',
    default: '',
    maxLength: 15,
    required: false,
    fullSpan: false
  },
  {
    label: 'Role',
    name: 'role',
    placeholder: 'Select Role',
    id: 'role',
    type: 'picklist',
    options: [
      { label: 'Teacher', value: 'Teacher' },
      { label: 'Principal', value: 'Principal' },
      { label: 'Admin', value: 'Admin' }
    ],
    default: 'Teacher',
    required: true,
    fullSpan: false
  },
  {
    label: 'Is Active',
    name: 'isActive',
    id: 'isActive',
    type: 'checkbox',
    default: false,
    fullSpan: false
  }
];

export default userFields;
