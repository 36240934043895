const testimonialColumns = () => [
    {
        accessorKey: 'name',
        header: 'Name',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
    {
        accessorKey: 'message',
        header: 'Message',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
    {
        accessorKey: 'role',
        header: 'Role',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
    {
        accessorFn: (row) => row.createdBy ? `${row.createdBy.firstName} ${row.createdBy.lastName}` : '', //accessorFn used to join multiple data into a single cell
        id: 'author', //id is still required when using accessorFn instead of accessorKey
        header: 'Author',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    }
];

export { testimonialColumns };