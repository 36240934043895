import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { ListItemIcon, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { deleteRecord } from '../../api/crud';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component

const RowActionMenuItems = ({ closeMenu, row, objectDetails }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleEditRecord = () => {
    navigate(`/admin/${objectDetails.apiName}/${row?._id}`)
    closeMenu(); // Close the menu after setting the modal state
  };

  const handleDeleteRecord = async () => {
    if (window.confirm(`Are you sure you want to delete this ${objectDetails.label}?`)) {
      closeMenu(); // Close the menu after setting the modal state
  
      const deletePromise = deleteRecord(objectDetails.apiName, row._id);
  
      toast.promise(
        deletePromise,
        {
          pending: `Deleting ${objectDetails.label}...`,
          success: `Successfully deleted ${objectDetails.label}!`,
          error: {
            render({ data }) {
              return `Error deleting ${objectDetails.label}: ${data?.response?.data?.message || data.message}`;
            }
          }
        }
      );
  
      try {
        await deletePromise;
        queryClient.invalidateQueries([objectDetails.apiNamePlural]); // Refresh list view query (records)
      } catch (error) {
        console.error(`Error deleting ${objectDetails.label}:`, error);
      }
    }
  };

  return (
    <>
      <MenuItem key="edit" onClick={handleEditRecord}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Edit
      </MenuItem>
      <MenuItem key="delete" onClick={handleDeleteRecord}>
        <ListItemIcon>
          <DeleteIcon color="error" />
        </ListItemIcon>
        Delete
      </MenuItem>
    </>
  );
};

export default RowActionMenuItems;