const isLocalhost = () => {
  const hostname = window.location.hostname.split('.')[1];
  return hostname === 'localhost' || hostname.endsWith('.localhost');
};

const getProtocol = () => {
  return isLocalhost() ? 'http' : 'https';
};

export { isLocalhost, getProtocol };
