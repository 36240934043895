// Used in the files: UserModal.js,..

function validation(values, formType) {
  const errors = {};

  // Form-specific validation logic...
  if (formType === 'user') {
    // User-specific validation logic...

    if (values.title === '') {
      errors.title = 'Title is Required';
    }

    if (values.firstName === '') {
      errors.firstName = 'First Name is Required';
    }

    if (values.lastName === '') {
      errors.lastName = 'Last Name is Required';
    }

    if (values.email === '') {
      errors.email = 'Email is Required';
    }

    if (values.status === '') {
      errors.status = 'Status is Required';
    }

    if (values.role === '') {
      errors.role = 'Role is Required';
    }

    if (!values.joined) {
      errors.joined = 'Date is Required';
    }

    if (!/^0\d{9}$/.test(values.phone) && values.phone !== '') {
      errors.phone =
        "Phone must follow South Africa's standard format. e.g 0723456789";
    }
  } else if (formType === 'settings') {
    if (values?.schoolName === '') {
      errors.schoolName = 'School Name is Required';
    }

    if (values.mission === '') {
      errors.mission = `School's mission is Required`;
    }

    if (values.phase === '') {
      errors.phase = 'Phase is Required';
    }

    if (values.coverPhoto === '') {
      errors.coverPhoto = 'Cover Photo is Required';
    }

    if (values.logo === '') {
      errors.logo = 'Logo is Required';
    }
  } else if (formType === 'subject') {
    if (values.name === '') {
      errors.name = 'Subject Name is Required';
    }

    if (values.code === '') {
      errors.code = 'Code is Required';
    }

    if (values.grade === '') {
      errors.grade = 'Grade is Required';
    }
  } else if (formType === 'Event') {
    if (values.title === '') {
      errors.title = 'Title is Required';
    }

    if (values.eventDate === '') {
      errors.eventDate = 'Event Date is Required';
    }

    if (values.body === '') {
      errors.body = 'Body is Required';
    }

    // if (values.body.length >= 250) {
    //   errors.body = 'Body must be less than 100 characters';
    // }
  } else if (formType === 'task') {
    if (values.title === '') {
      errors.title = 'Title is Required';
    }

    if (values.description === '') {
      errors.description = 'Description is Required';
    }

    if (values.status === '') {
      errors.status = 'Status is Required';
    }

    if (values.dueDate === '') {
      errors.eventDate = 'Due Date is Required';
    }
  } else if (formType === 'tenant-plan') {
    if (values.name === '') {
      errors.name = 'Tenant Plan Name is Required';
    }

    if (values.maxEvents <= 0) {
      errors.maxEvents = 'Max Event is Invalid';
    }

    if (values.maxNews <= 0) {
      errors.maxNews = 'Max News is Invalid';
    }

    if (values.maxStorage <= 0) {
      errors.maxStorage = 'Max Storage is Invalid';
    }
  } else if (formType === 'register') {
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    if (values.firstName === '') {
      errors.firstName = 'First Name is Required';
    }

    if (values.lastName === '') {
      errors.lastName = 'Last Name is Required';
    }

    if (values.email === '') {
      errors.email = 'Email is Required';
    }

    if (values.password === '') {
      errors.password = 'password is Required';
    }

    // if(values.confirmPassword === '' ){
    //     errors.confirmPassword = 'password is Required';
    // }
  } else if (formType === 'news') {
    if (values.title === '') {
      errors.title = 'Title is Required';
    }

    if (values.body === '') {
      errors.body = 'Body is Required';
    }

    if (values.body.length >= 10) {
      errors.body = 'Body is too long';
    }
  } else if (formType === 'tenant') {
    if (values.name === '') {
      errors.name = 'Name is Required';
    }

    if (values.email === '') {
      errors.email = 'Email is Required';
    }

    if (values.phone === '') {
      errors.phone = 'Phone is Required';
    }
  } else if (formType === 'school') {
    if (values.schoolName === '') {
      errors.schoolName = 'School Name is Required';
    }

    if (values.email === '') {
      errors.email = 'Email is Required';
    }

    if (values.telephone === '') {
      errors.telephone = 'Phone is Required';
    }

    if (values.phase === '') {
      errors.phase = 'Phase is Required';
    }

    if (values.primaryColor === '') {
      errors.primaryColor = 'Primary Color is Required';
    }

    if (values.primaryColorHover === '') {
      errors.primaryColorHover = 'Primary Color Hover is Required';
    }

    if (values.tenantId === '') {
      errors.tenantId = 'Tenant ID is Required';
    }
  } else if (formType === 'login') {
    if (values.email === '') {
      errors.email = 'Email is Required';
    }

    if (values.password === '') {
      errors.password = 'Password is Required';
    }
  } else if (formType === 'register') {
    if (values.firstName === '') {
      errors.firstName = 'First Name is Required';
    }

    if (values.lastName === '') {
      errors.lastName = 'Last Name is Required';
    }

    if (values.email === '') {
      errors.email = 'Email is Required';
    }

    if (values.password === '') {
      errors.password = 'Password is Required';
    }
  }

  return errors;
}

export default validation;