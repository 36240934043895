import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Button, Typography, Container, Box } from '@mui/material';
import SEO from '../../components/seo/SEO';
import Copyright from '../../components/copyright/Copyright';
import { resetPassword } from '../../api/authentication';
import { isLocalhost, getProtocol } from '../../utils/isLocalhost';

const initialForm = {
  newPassword: '',
  confirmPassword: '',
};

const PasswordReset = () => {
  const { token } = useParams();
  const [credentials, setCredentials] = useState(initialForm);
  const [formSubmitErrors, setFormSubmitErrors] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLinkExpired, setIsLinkExpired] = useState(false);

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    const genericErrorMessage = 'Something went wrong! Please try again later.';
    setIsSubmitting(true);
    try {
      await resetPassword(credentials, token);

      // setContextUser(response.user);
      setIsSubmitting(false);
      setCredentials(initialForm); // Reset form values

      const port = isLocalhost() ? window.location.port : '3000';
      const mainDomain = isLocalhost()
        ? `localhost.com:${port}`
        : process.env.REACT_APP_DOMAIN;
      window.location.replace(
        `${getProtocol()}://${
          process.env.REACT_APP_LOGIN_SUB_DOMAIN
        }.${mainDomain}`
      );
    } catch (error) {
      setIsLinkExpired(true);
      console.error(error);
      setIsSubmitting(false);
      setFormSubmitErrors(genericErrorMessage);
    }
  };

  const customButtonStyle = {
    backgroundColor: 'var(--primary-color)',
    color: 'var(--secondary-text-color)',
    '&:hover': {
      backgroundColor: 'var(--primary-color-dark)',
    },
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <SEO
        title="Password Rest | Salesdam"
        description="Password rest page Customer Management Tool"
      />
      {!isLinkExpired ? (
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" gutterBottom>
            Reset Password
          </Typography>
          <TextField
            name="newPassword"
            label="New Password"
            type="password"
            fullWidth
            margin="normal"
            value={credentials.newPassword}
            onChange={handleFormChange}
          />
          <TextField
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            fullWidth
            margin="normal"
            value={credentials.confirmPassword}
            onChange={handleFormChange}
          />
          {formSubmitErrors && (
            <small style={{ color: 'red', textAlign: 'center' }}>
              {formSubmitErrors}
            </small>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handlePasswordReset}
            sx={{ ...customButtonStyle, mt: 3, mb: 2 }}
            disabled={
              !credentials.newPassword ||
              credentials.newPassword !== credentials.confirmPassword ||
              isSubmitting
            }
          >
            Reset Password
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Password reset link has expired. Please request a new reset link.
        </Box>
      )}
      <Copyright />
    </Container>
  );
};

export default PasswordReset;
