import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from './context/AuthContext';
import { useCookies } from 'react-cookie';
import { isLocalhost, getProtocol } from './utils/isLocalhost';
import Login from './views/login/Login';
import Layout from './layout/Layout';
import Dashboard from './views/dashboard/Dashboard';
import RecordsListView from './views/recordsListView/RecordsListView';
import DetailsPage from './views/detailsPage/DetailsPage';
import SchoolWebsitePages from './views/schoolWebsitePages/SchoolWebsitePages';
import SchoolSettings from './views/settings/schoolSettings/SchoolSettings';
import NotFoundPage from './views/notFoundPage/NotFoundPage';
import ForgotPassword from './views/resetPassword/ForgotPassword';
import ResetPassword from './views/resetPassword/ResetPassword';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [cookies] = useCookies(['subdomain']);

  const subdomain = cookies.subdomain;
  const appSubdomain = window.location.hostname.split('.')[0];

  if (subdomain && subdomain !== appSubdomain) {
    logout();
  }

  if (!isAuthenticated()) {
    // Check if the app is running on localhost or 127.0.0.1
    const isLocal = isLocalhost();
    const protocol = getProtocol();

    // Retrieve the port number, if it exists
    const port = window.location.port ? `:${window.location.port}` : '';

    // Get the production domain from the environment variables
    const prodDomain = process.env.REACT_APP_DOMAIN;
    const loginSubDomain = process.env.REACT_APP_LOGIN_SUB_DOMAIN;

    // Construct the URL for local development, including the port number
    const localURL = `${protocol}://${loginSubDomain}.localhost.com${port}`;

    // Construct the URL for the production environment
    const prodURL = `${protocol}://${loginSubDomain}.${prodDomain}`;

    // Redirect to the appropriate URL based on the environment
    window.location.href = isLocal ? localURL : prodURL;

    // Return null to prevent rendering any component before redirection
    return null;
  }

  // If authenticated, render the protected children components
  return children;
};

const PageRouter = () => {
  const { isTechSupportTenant } = useContext(AuthContext);

  const commonPaths = [
    { component: <Login />, link: '/login', isAuthRequired: false },
    { component: <NotFoundPage />, link: '*', isAuthRequired: true },
    {
      component: <Layout component={<Dashboard />} />,
      link: '/',
      isAuthRequired: true,
    },
    {
      component: <Layout component={<RecordsListView />} />,
      link: '/admin/:objectNamePlural',
      isAuthRequired: true,
    },
    {
      component: <Layout component={<DetailsPage />} />,
      link: '/admin/:objectName/:recordId',
      isAuthRequired: true,
    },
  ];

  const schoolPaths = [
    ...commonPaths,
    {
      component: <Layout component={<SchoolWebsitePages />} />,
      link: '/school-website-pages',
      isAuthRequired: true,
    },
    {
      component: <Layout component={<SchoolWebsitePages />} />,
      link: '/school-website-pages/admissions',
      isAuthRequired: true,
    },
    {
      component: <Layout component={<SchoolWebsitePages />} />,
      link: '/school-website-pages/about-us',
      isAuthRequired: true,
    },
    {
      component: <Layout component={<SchoolSettings />} />,
      link: '/school-settings',
      isAuthRequired: true,
    },
  ];

  const techSupportPaths = [
    ...commonPaths,
    // { component: <Layout component={<Tasks />}  />, link: '/admin/tasks', isAuthRequired: true },
    // { component: <Layout component={<Cases />}  />, link: '/admin/tasks', isAuthRequired: true }
  ];

  // const paths = [
  //     { component: <Login />, link: '/login', isAuthRequired: false},
  //     { component: <Layout component={<Dashboard />} />, link: '/', isAuthRequired: true },
  //     { component: <Layout component={<RecordsListView />} />, link: '/admin/:objectNamePlural', isAuthRequired: true },
  //     { component: <Layout component={<DetailsPage />} />, link: '/admin/:objectName/:recordId', isAuthRequired: true },
  //     { component: <Layout component={<SchoolWebsitePages />} />, link: '/school-website-pages', isAuthRequired: true },
  //     { component: <Layout component={<SchoolWebsitePages />} />, link: '/school-website-pages/admissions', isAuthRequired: true },
  //     { component: <Layout component={<SchoolWebsitePages />} />, link: '/school-website-pages/about-us', isAuthRequired: true },
  //     { component: <Layout component={<SchoolSettings />} />, link: '/school-settings', isAuthRequired: true },
  //     { component: <NotFoundPage/>, link: '*', isAuthRequired: true }
  // ];

  const subdomain = window.location.hostname.split('.')[0];

  const isTechSupport = isTechSupportTenant();
  const pathsToUse = isTechSupport ? techSupportPaths : schoolPaths;

  return (
    <Router>
      <Routes>
        {subdomain === process.env.REACT_APP_LOGIN_SUB_DOMAIN ? (
          <>
            <Route path="*" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/password-reset/:userId/:token"
              element={<ResetPassword />}
            />
          </>
        ) : (
          pathsToUse.map((pathItem) =>
            pathItem.isAuthRequired ? (
              <Route
                key={pathItem.link}
                path={pathItem.link}
                element={<ProtectedRoute>{pathItem.component}</ProtectedRoute>}
              />
            ) : (
              <Route
                key={pathItem.link}
                path={pathItem.link}
                element={pathItem.component}
              />
            )
          )
        )}
      </Routes>
    </Router>
  );
};

export default PageRouter;
