import api from './api';

const handleRequest = async (url, data) => {
  try {
    const response = await api.post(`/api/auth${url}`, data);
    return response.data;
  } catch (error) {
    console.error(`Error for ${url}:`, error);
    throw error;
  }
};

const login = async (loginForm) => {
  return handleRequest('/login', loginForm);
};

const logout = async (user) => {
  return handleRequest('/logout', { user });
};

const resetPassword = async (form, token) => {
  return handleRequest(`/password-reset-payload/${token}`, form);
};

export { login, logout, resetPassword };
