import { Typography, Paper } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import styles from './css/SchoolWebsitePages.module.css';
import HomePage from './pages/HomePage';
import AdmissionsPage from './pages/AdmissionsPage';
import AboutUsPage from './pages/AboutUsPage';

const sidebarItems = [
  {
    text: 'Home Page',
    link: '/school-website-pages',
    component: <HomePage />,
  },
  {
    text: 'Admissions Page',
    link: '/school-website-pages/admissions',
    component: <AdmissionsPage />,
  },
  {
    text: 'About Us Page',
    link: '/school-website-pages/about-us',
    component: <AboutUsPage />,
  },
];

const Sidebar = () => {
  const location = useLocation();

  return (
    <div className={styles['inner-sidebar']}>
      <Typography variant="h6">School Website Pages</Typography>
      <ul>
        {sidebarItems.map((item) => (
          <li key={item.link}>
            <Link
              to={item.link}
              className={location.pathname === item.link ? styles['active-item'] : ''}
            >
              {item.text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const SchoolWebsitePages = () => {
  const location = useLocation();

  const currentPage = sidebarItems.find(
    (page) => page.link === location.pathname
  );
  const currentPageComponent = currentPage ? currentPage.component : null;

  return (
    <div className={styles['school-webiste-pages']}>
      <Sidebar />
      <main className={styles['main-content']}>
        <Paper sx={{padding: '20px 0'}}>{currentPageComponent}</Paper>
      </main>
    </div>
  );
};

export default SchoolWebsitePages;
