const planFields = [
  {
    label: 'Plan',
    name: 'name',
    id: 'name',
    type: 'text',
    placeholder: 'Enter School Plan Name',
    default: '',
    maxLength: 140,
    required: true,
    fullSpan: true
  },
  {
    label: 'Description',
    name: 'description',
    placeholder: 'Enter Subject Description',
    id: 'description',
    type: 'textarea',
    default: '',
    maxLength: 250,
    required: true,
    fullSpan: true
  },
  {
    label: 'Price (R per month)',
    name: 'price',
    placeholder: 0,
    id: 'price',
    type: 'number',
    default: '',
    maxLength: 10,
    required: true,
    fullSpan: true
  },
  {
    label: 'Sorting Order',
    name: 'sortOrder',
    placeholder: 0,
    id: 'sortOrder',
    type: 'number',
    default: '',
    maxLength: 10,
    required: true,
    fullSpan: true
  },
  {
    label: 'Max Number of News',
    name: 'maxNews',
    placeholder: 0,
    id: 'maxNews',
    type: 'number',
    default: '',
    // maxLength: 100,
    required: true,
    fullSpan: true
  },
  {
    label: 'Max Number of Events',
    name: 'maxEvents',
    placeholder: 0,
    id: 'maxEvents',
    type: 'number',
    default: '',
    // maxLength: 100,
    required: true,
    fullSpan: true
  },
  {
    label: 'Max Number of Staff',
    name: 'maxStaff',
    placeholder: 0,
    id: 'maxStaff',
    type: 'number',
    default: '',
    // maxLength: 100,
    required: true,
    fullSpan: true
  },
  {
    label: 'Max Number of Subjects',
    name: 'maxSubjects',
    placeholder: 0,
    id: 'maxSubjects',
    type: 'number',
    default: '',
    // maxLength: 100,
    required: true,
    fullSpan: true
  },
  {
    label: 'Max Storage (mb)',
    name: 'maxStorage',
    placeholder: 0,
    id: 'maxStorage',
    type: 'number',
    default: '',
    // maxLength: 100,
    required: true,
    fullSpan: true
  },
];

export default planFields;
