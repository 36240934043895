import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { formatDate } from '../../utils/dateUtils';

const userColumns = () => [
  {
    accessorFn: (row) => `${row.title || ''} ${row.firstName} ${row.lastName}`, //accessorFn used to join multiple data into a single cell
    id: 'name', //id is still required when using accessorFn instead of accessorKey
    header: 'Name',
    size: 250,
    Cell: ({ renderedCellValue, row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Avatar alt="user" src={row.original.picture} />
        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
        <span>{renderedCellValue}</span>
      </Box>
    ),
  },
  {
    accessorKey: 'email',
    header: 'Email',
    // enableClickToCopy: true,
  },
  {
    accessorKey: 'role',
    header: 'Role',
  },
  {
    id: 'isActive',
    header: 'Is Active',
    accessorFn: (row) => (
      <Checkbox
        checked={row.isActive}
        disabled // This makes the checkbox read-only
      />
    ),
  },
  {
    accessorFn: (row) => formatDate(row?.joined),
    id: 'joined',
    header: 'Joined',
  },
  // {
  //   accessorKey: 'age',
  //   header: 'Age',
  //   enableEditing: false,
  // },
];

export { userColumns };
