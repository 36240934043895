const testimonialFields = [
  {
    label: 'Published',
    name: 'published',
    id: 'published',
    type: 'checkbox',
    default: true,
    fullSpan: true
  },
  {
    label: 'Name',
    name: 'name',
    placeholder: 'Enter Name',
    id: 'name',
    type: 'text',
    maxLength: 30,
    default: '',
    required: true,
    fullSpan: true
  },
  {
    label: 'Message',
    name: 'message',
    placeholder: 'Enter Message',
    id: 'message',
    type: 'textarea',
    maxLength: 250,
    default: '',
    required: true,
    fullSpan: true
  },
  {
    label: 'Role',
    name: 'role',
    placeholder: 'Select Role',
    id: 'role',
    type: 'picklist',
    options: [
      { label: 'Parent', value: 'Parent' },
      { label: 'Teacher', value: 'Teacher' },
      { label: 'Other', value: 'Other'}
    ],
    default: 'Other',
    required: false,
    fullSpan: true
  }
];

export default testimonialFields;
