const schoolColumns = () => [
    {
        accessorKey: 'name',
        header: 'School Name',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
    {
        accessorKey: 'email',
        header: 'Email',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
    {
        accessorKey: 'phone',
        header: 'Phone',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
    {
        accessorFn: (row) => row.plan ? row.plan.name : '', //accessorFn used to join multiple data into a single cell
        id: 'plan', //id is still required when using accessorFn instead of accessorKey
        header: 'Tenant Plan',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    }
];

export { schoolColumns };