import { Button } from '@mui/material';

const ActionButton = ({ actionType, onClick, isSubmitting, ...props }) => {
  const getButtonProps = () => {
    switch (actionType) {
      case 'save':
        return {
          variant: 'contained',
          text: 'Save',
          styles: {
            backgroundColor: 'var(--primary-color',
          },
        };
      case 'cancel':
        return {
          variant: 'outlined',
          text: 'Cancel',
          styles: {
            color: 'var(--primary-color',
          },
        };
      case 'delete':
        return { variant: 'contained', color: 'error', text: 'Delete' };
      default:
        return { variant: 'contained', color: 'default', text: 'Button' };
    }
  };

  const { variant, color, text, styles } = getButtonProps();

  return (
    <Button
      sx={styles}
      variant={variant}
      color={color}
      onClick={onClick}
      disabled={isSubmitting}
      size="small"
      {...props}
    >
      {text}
    </Button>
  );
};

export default ActionButton;
