import { formatDateTime } from "../../utils/dateUtils";

const newsColumns = () => [
    {
        accessorKey: 'title',
        header: 'Title',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
    {
        accessorFn: (row) => row.createdBy ? `${row.createdBy.firstName} ${row.createdBy.lastName}` : '', //accessorFn used to join multiple data into a single cell
        id: 'author', //id is still required when using accessorFn instead of accessorKey
        header: 'Author',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
    {
        accessorFn: (row) => formatDateTime(row?.createdAt),
        id: 'createdAt',
        header: 'Created Date',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    },
    {
        accessorFn: (row) => formatDateTime(row?.updatedAt),
        id: 'updatedAt',
        header: 'Updated Date',
        minSize: 100, //min size enforced during resizing
        maxSize: 400, //max size enforced during resizing
    }
];

export { newsColumns }