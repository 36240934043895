const subjectColumns = () => [
    {
        accessorKey: 'name',
        header: 'Name'
    },
    {
        accessorKey: 'code',
        header: 'Code'
    },
    {
        accessorKey: 'grade',
        header: 'Grade'
    }
];

export { subjectColumns };