const achievementFields = [
  {
    label: 'Published',
    name: 'published',
    id: 'published',
    type: 'checkbox',
    default: true,
    fullSpan: true
  },
  {
    label: 'Title',
    name: 'title',
    placeholder: 'Enter Title',
    id: 'title',
    type: 'text',
    default: '',
    required: true,
    fullSpan: true
  },
  {
    label: 'Description',
    name: 'description',
    id: 'description',
    type: 'textarea',
    default: '',
    required: true,
    fullSpan: true
  },
  {
    label: 'Date Achieved',
    name: 'dateAchieved',
    id: 'dateAchieved',
    type: 'date',
    default: '',
    required: true,
    fullSpan: true
  },
  {
    label: 'Awarded By',
    name: 'awardedBy',
    id: 'awardedBy',
    type: 'text',
    default: '',
    required: true,
    fullSpan: true
  },
  // {
  //   label: 'Certificate URL',
  //   name: 'certificateURL',
  //   id: 'certificateURL',
  //   type: 'text',
  //   default: ''
  // },
  {
    label: 'Category',
    name: 'category',
    id: 'category',
    type: 'picklist',
    options: [
      { label: 'Academic', value: 'Academic' },
      { label: 'Sports', value: 'Sports' },
      { label: 'Cultural', value: 'Cultural' },
      { label: 'Other', value: 'Other'}
    ],
    default: '',
    required: true,
    fullSpan: true
  }
];

export default achievementFields;
