const eventFields = [
  {
    label: 'Title',
    name: 'title',
    id: 'eventTitle',
    type: 'text',
    placeholder: 'Enter Event Title',
    default: '',
    maxLength: 70,
    required: true,
    fullSpan: true
  },
  {
    label: 'Published',
    name: 'published',
    id: 'published',
    type: 'checkbox',
    default: false,
    fullSpan: true
  },
  {
    label: 'Event Date & Time',
    name: 'eventDate',
    id: 'eventDate',
    type: 'datetime',
    default: '',
    required: true,
    fullSpan: true
  },
  {
    label: 'To Date & Time',
    name: 'toDate',
    id: 'toDate',
    type: 'datetime',
    default: '',
    required: true,
    fullSpan: true
  },
  {
    label: 'Location',
    name: 'location',
    id: 'eventLocation',
    type: 'text',
    placeholder: 'Enter Event Location',
    default: '',
    maxLength: 140,
    required: true,
    fullSpan: true
  },
  {
    label: 'type',
    name: 'type',
    id: 'eventType',
    type: 'picklist',
    options: [
      { label: 'Parents Meeting', value: 'Parents Meeting' },
      { label: 'Family Event', value: 'Family Event' },
      { label: 'Open Meeting', value: 'Open Meeting' },
      { label: 'Information Session', value: 'Information Session' },
      { label: 'Fundraising', value: 'Fundraising' },
      { label: 'Sports Day', value: 'Sports Day' },
      { label: 'Report Collection', value: 'Report Collection' },
      { label: 'Holiday', value: 'Holiday' },
    ],
    default: '',
    required: false,
    fullSpan: true
  },
  {
    label: 'Body',
    name: 'body',
    id: 'eventBody',
    type: 'textarea',
    placeholder: 'Enter Event Body',
    default: '',
    maxLength: 250,
    required: true,
    fullSpan: true
  },
  // {
  //   label: 'Start Time',
  //   name: 'startTime',
  //   id: 'eventStartTime',
  //   type: 'time',
  //   default: '',
  //   required: true,
  //   fullSpan: true
  // },
  // {
  //   label: 'End Time',
  //   name: 'EndTime',
  //   id: 'eventEndTime',
  //   type: 'time',
  //   default: '',
  //   required: true,
  //   fullSpan: true
  // },
];

export default eventFields;
