import styles from './Field.module.css';
import { useState } from 'react';
import { Checkbox, FormControlLabel, Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LookupField from './LookupField';
import { Editor } from '@tinymce/tinymce-react';

const Field = ({ lookupTo, id, label, textAreaRows, maxLength, required, name, helpText, placeholder, type, options, value, disabled, onChange, errorMessage, customStyles, width }) => {
  const showError = errorMessage && errorMessage.length > 0;
  const [showHelpText, setShowHelpText] = useState(false);
  const [fileName, setFileName] = useState('');

  const toggleHelpText = () => {
    setShowHelpText(!showHelpText);
  };

  const onFileChange = e => {
    const files = e.target.files;
    const file = files[0];

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const fileString = reader.result;

      onChange({
        target: {
          name,
          value: fileString
        }
      })
    };

  };

  const removeFile = () => {
    setFileName('');
    onChange({
      target: {
        name,
        value: ''
      }
    });
  };

  return (
    <div className={styles['Custom-field-container']} style={customStyles}>
      <label htmlFor={id} className={styles['field-label']}>
        {label} {required && <span className={styles['required']}>*</span>}
        {helpText && (
          <span className={styles['info-icon']} onMouseEnter={toggleHelpText} onMouseLeave={toggleHelpText}>
            <InfoOutlinedIcon fontSize="small" />
          </span>
        )}
        {helpText && (
          <span className={styles['help-text']}>{helpText}</span>
        )}
      </label>
      {['text', 'search', 'email', 'number', 'date', 'time'].includes(type) && (
        <>
          <input disabled={disabled} maxLength={maxLength} id={id} className={showError ? styles['error'] : ''} name={name} type={type} placeholder={placeholder} value={value || ''} onChange={onChange} />
          {maxLength && (
            <div className={styles['char-counter']}>{`${value?.length}/${maxLength} characters left`}</div>
          )}
          {showError && <div className={styles['error-message']}>{errorMessage}</div>}
        </>
      )}
      {type === 'datetime' && (
        <>
          <input disabled={disabled} maxLength={maxLength} id={id} className={showError ? styles['error'] : ''} type="datetime-local" name={name} value={value || ''} onChange={onChange} />
          {showError && <div className={styles['error-message']}>{errorMessage}</div>}
        </>
      )}
      {type === 'picklist' && (
        <>
          <select
            disabled={disabled}
            id={id}
            className={showError ? styles['error'] : ''}
            name={name}
            value={value || ''}
            onChange={onChange}
          >
            <option value="" disabled>--NONE--</option>
            {options && options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {showError && <div className={styles['error-message']}>{errorMessage}</div>}
        </>
      )}
      {type === 'radio' && (
        <>
          {options?.map((option) => (
            <div key={option} className={styles['radio-option']}>
              <input
                type="radio"
                id={`${id}_${option}`}
                name={name}
                value={option}
                checked={value === option}
                onChange={onChange}
                disabled={disabled}
              />
              <label htmlFor={`${id}_${option}`}>{option}</label>
            </div>
          ))}
          {showError && <div className={styles['error-message']}>{errorMessage}</div>}
        </>
      )}
      {type === 'textarea' && (
        <>
          <textarea
            disabled={disabled}
            rows={textAreaRows ? textAreaRows : 2}
            maxLength={maxLength} id={id}
            className={showError ? styles['error'] : ''}
            name={name}
            value={value}
            onChange={onChange}
            style={{ resize: 'vertical' }}>
          </textarea>
          {maxLength && (
            <div className={styles['char-counter']}>{`${value?.length}/${maxLength} characters left`}</div>
          )}
          {showError && <div className={styles['error-message']}>{errorMessage}</div>}
        </>
      )}
      {type === 'checkbox' && (
        <div className={styles['checkbox-field']}>
          <FormControlLabel id={id} control={<Checkbox disabled={disabled} checked={value} onChange={onChange} />} name={name} />
          {showError && <div className={styles['error-message']}>{errorMessage}</div>}
        </div>
      )}
      {type === 'output' && (
        <>
          <input disabled id={id} name={name} type="text" placeholder={placeholder} value={value || ''} />
        </>
      )}
      {type === 'lookup' && (
        <LookupField disabled={disabled} id={id} name={name} lookupTo={lookupTo} placeholder={placeholder} onChange={onChange} lookupRecordId={value} />
      )}
      {type === 'file' && (
        <div className={styles['file-input-container']}>
          <input
            type='file'
            accept='.jpg, .png|image/*'
            multiple={false}
            id={id}
            name={name}
            onChange={onFileChange}
          />
          {value && (
            <div className={styles['file-details']}>
              <Box
                component="img"
                sx={{
                  width: width || 350
                }}
                alt={fileName}
                src={value}
              />
              <button type="button" className={styles['remove-file-button']} onClick={removeFile}>
                Remove
              </button>
            </div>
          )}
          {showError && <div className={styles['error-message']}>{errorMessage}</div>}
        </div>
      )}
      {type === 'richtext' && (
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          id={id}
          value={value || ''}
          init={{
            height: 300,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help'
          }}
          disabled={disabled}
          onEditorChange={(content) => onChange({ target: { name, value: content } })}
        />
      )}
    </div>
  );
};

export default Field;