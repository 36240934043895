import './App.css';
import Routes from './Routes';

function App() {
  return <div className="App">
    <Routes />
  </div>;
}

export default App;
