import Checkbox from '@mui/material/Checkbox';

const galleryColumns = () => [
  {
    id: 'picture',
    header: 'Picture',
    accessorFn: (row) => (
      <img
        src={row.picture}
        alt={row.title}
        width="100"
      />
    ),
  },
  {
    accessorKey: 'title',
    header: 'title',
  },
  {
    accessorKey: 'description',
    header: 'Description',
  },
  {
    accessorKey: 'grade',
    header: 'Grade',
  },
  {
    id: 'published',
    header: 'Published',
    accessorFn: (row) => (
      <Checkbox
        checked={row.published}
        disabled // This makes the checkbox read-only
      />
    ),
  },
];

export { galleryColumns };
